import {
  GET_PITCHES,
  JSONUploadState,
  SET_AUTO_LOCATE_ACTIVE,
  SET_DOUBLE_PITCH_SETUP,
  SET_DEMONSTRATION_PITCH,
  SET_PITCH_IN_USE,
  TOGGLE_AUTO_SETUP,
  UPDATE_ANCHOR_BUMP,
  UPDATE_MASTER,
  UPDATE_PITCH_SETUP_TAG_LIST,
  UPDATE_SECOND_PITCH_SETUP_TAG_LIST,
  UPDATE_PITCH_SETUP_TYPE,
  UPDATE_PITCH_SIDE
} from './types'

import { setError } from '../../ui/error/actions'
import { setSuccess } from '../../ui/success/actions'
import { updateSession } from '../sessions/actions'
import { request } from '../../utils/request_handler'
import { API_ROOT_URL } from '../../const'
import { TotalStationRequestBody } from '../../model/external/total_station'
import { ThunkAction } from 'redux-thunk'
import { AxiosRequestConfig } from 'axios'
import { AppDispatch } from '../../store'

// Get teams of logged in user
export function getPitches(organisation) {
  return (dispatch, getState) => {
    const success = (response) => {
      dispatch({
        type: GET_PITCHES,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get organisation's pitches. API request failed. Check log for more detail.`
        })
      )
    }
    const options: AxiosRequestConfig = {
      url: `organisations/${organisation.id}/pitches`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export function getPitchConfig(backUpPitchId) {
  return (dispatch, getState) => {
    const success = (response) => {
      dispatch(setPitchInUse(response.data))
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get pitch config. API request failed. Check log for more detail.`
        })
      )
    }
    const options: AxiosRequestConfig = {
      url: `config/pitch`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export function setPitchConfig(pitchId) {
  return (dispatch, getState) => {
    const pitch = getState().pitches.items[pitchId]
    const success = (response) => {
      dispatch(updateSession({ pitch }))
      dispatch(setPitchInUse(pitch))
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to set pitch config for this session. API request failed. Check log for more detail.`
        })
      )
    }
    const options: AxiosRequestConfig = {
      url: `config/pitch`,
      data: { id: pitchId },
      method: 'POST',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export function setPitchInUse(pitch) {
  console.log(SET_PITCH_IN_USE, pitch)
  return {
    type: SET_PITCH_IN_USE,
    payload: pitch
  }
}

export function removePitchConfig() {
  return {
    type: SET_PITCH_IN_USE,
    payload: {}
  }
}

export function updatePitchSide(pitchSide) {
  return {
    type: UPDATE_PITCH_SIDE,
    payload: pitchSide
  }
}

export function toggleAutoSetup(bool) {
  return {
    type: TOGGLE_AUTO_SETUP,
    payload: bool
  }
}

export function updatePitchSetupType(type) {
  return {
    type: UPDATE_PITCH_SETUP_TYPE,
    payload: type
  }
}

export function updateTagList(index, tagId) {
  return {
    type: UPDATE_PITCH_SETUP_TAG_LIST,
    payload: { index, tagId }
  }
}

export function updateSecondPitchTagList(index, tagId) {
  return {
    type: UPDATE_SECOND_PITCH_SETUP_TAG_LIST,
    payload: { index, tagId }
  }
}

export function autoLocate(data) {
  return (dispatch, getState) => {
    const success = (response) => {
      dispatch({ type: SET_AUTO_LOCATE_ACTIVE, payload: false })
      dispatch(setSuccess({ message: `Auto setup successfully completed` }))
      dispatch(setPitchInUse(response.data))
      dispatch(updateSession({ pitch: response.data }))
    }
    const error = (error, errorType) => {
      dispatch({ type: SET_AUTO_LOCATE_ACTIVE, payload: false })
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to complete auto setup: ${error.response.data.error}`
        })
      )
    }

    // Set master
    const { master, anchorBump } = getState().pitches
    if (master !== 'none') {
      data.masterId = master
    }

    data.anchorBump = parseFloat(anchorBump)
    if (isNaN(data.anchorBump)) data.anchorBump = 0

    console.log('AutoLocate data', data)

    const options: AxiosRequestConfig = {
      url: `config/autolocate`,
      data,
      method: 'POST',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    dispatch({ type: SET_AUTO_LOCATE_ACTIVE, payload: true })
    return request(options, success, error, dispatch)
  }
}

export function postManualPitchSetup(data, callback?) {
  return (dispatch, getState) => {
    const success = (response) => {
      dispatch({ type: SET_AUTO_LOCATE_ACTIVE, payload: false })
      dispatch(setSuccess({ message: `Manual setup successfully completed` }))
      dispatch(setPitchInUse(response.data))
      dispatch(updateSession({ pitch: response.data }))
      if (callback) callback()
    }
    const error = (error, errorType) => {
      dispatch({ type: SET_AUTO_LOCATE_ACTIVE, payload: false })
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to complete manual setup. API request failed. Check log for more detail.`
        })
      )
    }

    // Set master
    const { master, anchorBump } = getState().pitches
    if (master !== 'none') {
      data.masterId = master
    }

    data.anchorBump = parseFloat(anchorBump)
    if (isNaN(data.anchorBump)) data.anchorBump = 0

    const options: AxiosRequestConfig = {
      url: `config/manual_config`,
      data,
      method: 'POST',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    dispatch({ type: SET_AUTO_LOCATE_ACTIVE, payload: true })
    return request(options, success, error, dispatch)
  }
}

export function postTotalStation(data: TotalStationRequestBody) {
  return (dispatch, getState) => {
    const success = (response) => {
      dispatch(
        setSuccess({ message: `Total station setup successfully completed` })
      )
      dispatch(setPitchInUse(response.data))
      dispatch(updateSession({ pitch: response.data }))
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to complete total station setup. API request failed. Check log for more detail.`
        })
      )
    }

    if (!data.name) delete data.name

    const options: AxiosRequestConfig = {
      url: `config/total_station`,
      data,
      method: 'POST',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export function postUploadJSON(
  data: JSONUploadState
): ThunkAction<void, any, any, any> {
  return (dispatch, getState) => {
    const success = (response) => {
      dispatch(
        setSuccess({ message: `Total station setup successfully completed` })
      )
      dispatch(setPitchInUse(response.data))
      dispatch(updateSession({ pitch: response.data }))
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to complete total station setup. API request failed. Check log for more detail.`
        })
      )
    }

    return postUploadJsonWithoutRedux(data, success, error, dispatch)
  }
}

function postUploadJsonWithoutRedux(
  data: JSONUploadState,
  onSuccess,
  onError,
  dispatch?: AppDispatch
): Promise<any> {
  const options: AxiosRequestConfig = {
    url: `config/pitch/anchors`,
    data,
    method: 'POST',
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return request(options, onSuccess, onError, dispatch)
}

export function updateMaster(anchorId) {
  return {
    type: UPDATE_MASTER,
    payload: anchorId
  }
}

export function updateAnchorBump(bump) {
  return {
    type: UPDATE_ANCHOR_BUMP,
    payload: bump
  }
}

export function setDoublePitchSetup(bool) {
  return {
    type: SET_DOUBLE_PITCH_SETUP,
    payload: bool
  }
}

export function setDemonstrationPitch(bool) {
  return {
    type: SET_DEMONSTRATION_PITCH,
    payload: bool
  }
}
