import { SlowSensor } from './SlowSensor/SlowSensor'
import { Controller } from './Controller/Controller'
import { CFLHardware } from './CFLHardware/CFLHardware'
import { Pitch } from './Pitch/Pitch'
import { Hardware } from './Hardware/Hardware'
import { Tabs } from '../../components/Views/Main/Main.types'
import { useHardwareOptions } from './options'

export enum DiagnosticsTabs {
  ANCHOR_HEALTH = 'ANCHOR_HEALTH',
  PITCH = 'PITCH',
  HARDWARE_TABLE = 'HARDWARE_TABLE',
  HARDWARE_MATRIX = 'HARDWARE_MATRIX',
  HARDWARE_STATE = 'HARDWARE_STATE',
  CONTROLLER = 'CONTROLLER'
}

export const tabConfig: Tabs = {
  // New CFL Hardware Table
  [DiagnosticsTabs.HARDWARE_TABLE]: {
    key: DiagnosticsTabs.HARDWARE_TABLE,
    name: 'Overview',
    Content: CFLHardware,
    useOptions: useHardwareOptions
  },
  [DiagnosticsTabs.HARDWARE_MATRIX]: {
    // Original Matrix of all hardware devices
    key: DiagnosticsTabs.HARDWARE_MATRIX,
    name: 'Anchors',
    Content: Hardware,
    useOptions: useHardwareOptions
  },
  [DiagnosticsTabs.PITCH]: {
    // New Anchor Health for CFL with dropdown and daemon stats
    key: DiagnosticsTabs.PITCH,
    name: 'Pitch',
    Content: Pitch,
    strack: {
      options: {
        anchors: true,
        tagInfo: true,
        babylonActive: false,
        canvasId: 'pitch',
        cover: 'rugby-cover',
        show2DOnly: true,
        tracking: true,
        anchorSetup: true,
        diags: true
      },
      canvasStyle: {
        width: '65%',
        height: '100%',
        padding: '10px 10px 10px 10px',
        boxSizing: 'border-box',
        position: 'absolute',
        bottom: 0,
        left: 0
      } as const
    }
  },
  [DiagnosticsTabs.HARDWARE_STATE]: {
    key: DiagnosticsTabs.HARDWARE_STATE,
    name: 'HW State',
    Content: SlowSensor
  },
  [DiagnosticsTabs.CONTROLLER]: {
    key: DiagnosticsTabs.CONTROLLER,
    name: 'Controller',
    Content: Controller
  }
}

export const useDiagnositcsTabConfig = (view) => {
  const tabs = { ...view.tabs }

  const initialTab = tabs[DiagnosticsTabs.HARDWARE_TABLE]

  return {
    tabs,
    initialTab
  }
}
