import { defaultPitch } from './pitch'
import { rugbyLeagueEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FormattedEventData } from '../../events/types'
import { FlightEventTypeGroup } from '../../events/flight/data_types'
import dataTypes from './data_types_config.json'
import {
  getMetricTypeGroup,
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../../metrics/data_types'

export const rugbyLeagueProps = {
  eventTypes: rugbyLeagueEventTypes,
  playerSummaryMetricTypes: getMetricTypeGroup(
    getMetricTypesConfigFromJson(
      dataTypes.playerSummary.metrics,
      metricTypesConfig
    )
  ),
  pitch: {
    default: defaultPitch,
    defaultDoublePitch: null,
    pitchPointCount: 38,
    setupTypes: pitchSetupTypes,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 11,
    tenYardMarkings: 10,
    crossfieldLines: [
      { name: 'Try line', lower: 'P2', upper: 'P29' },
      { name: '10 meter line', lower: 'P3', upper: 'P28' },
      { name: '20 meter line', lower: 'P4', upper: 'P27' },
      { name: '30 meter line', lower: 'P5', upper: 'P26' },
      { name: '40 meter line', lower: 'P6', upper: 'P25' },
      { name: 'Halfway line', lower: 'P7', upper: 'P24' },
      { name: '40 meter line', lower: 'P8', upper: 'P23' },
      { name: '30 meter line', lower: 'P9', upper: 'P22' },
      { name: '20 meter line', lower: 'P10', upper: 'P21' },
      { name: '10 meter line', lower: 'P11', upper: 'P20' },
      { name: 'Try line', lower: 'P12', upper: 'P19' }
    ],
    enableBench: true,
    enableRotate: false,
    defaultDrillRegion: {
      P1: 'P25',
      P2: 'P17',
      P3: 'P10',
      P4: 'P2',
      P5: 'P32',
      P6: 'P33',
      P7: 'P31',
      P8: 'P34'
    } as const,
    drillsGoalsOrPosts: {
      enabled: false,
      dimensions: {
        large: {
          width: 7.32,
          height: 2.44,
          depth: 2.44
        },
        small: {
          width: 1.914,
          height: 1.914,
          depth: 1.61
        }
      }
    },
    drillSnapLines: [
      'P1',
      'P2',
      'P3',
      'P4',
      'P5',
      'P6',
      'P7',
      'P8',
      'P9',
      'P10',
      'P11',
      'P12',
      'P13'
    ],
    autosetupTagPositions: ['P7', 'P13', 'P19'],
    pitchCornerKeys: {
      topLeft: 'P30',
      topRight: 'P18',
      bottomRight: 'P13',
      bottomLeft: 'P1'
    },
    leftAutosetupRegion: {
      topLeft: 'P30',
      topRight: 'P24',
      bottomRight: 'P7',
      bottomLeft: 'P1'
    },
    rightAutosetupRegion: {
      topLeft: 'P24',
      topRight: 'P18',
      bottomRight: 'P13',
      bottomLeft: 'P7'
    },
    leftTeamRegion: {
      topLeft: 'P10',
      topRight: 'P10',
      bottomRight: 'P1',
      bottomLeft: 'P1'
    },
    rightTeamRegion: {
      topLeft: 'P8',
      topRight: 'P8',
      bottomRight: 'P3',
      bottomLeft: 'P3'
    }
  },
  features: {
    targetEvents: ['kick'],
    positionNumbers: true,
    maxPlayers: 17,
    defaultUnitSystem: 'British',
    keyboardShortcuts: {
      screenName: 'Validation - Kick Detail Card',
      shortcuts: {
        u: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.kick?.value
            )
          },
          outcome: outcomeTypes.items.uncontested.value
        },
        c: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.kick?.value
            )
          },
          outcome: outcomeTypes.items.contestedLost.value
        }
      }
    },
    recentEventTableMetrics: [
      'inPitchDistance',
      'inPitchHangTime',
      'speed',
      'territorialGain'
    ],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: false,
    bibs: {}
  } as const
}
