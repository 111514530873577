import React, { useEffect, useMemo, useState } from 'react'
import {
  useActiveFormattedDrill,
  useDrills,
  useFormattedDrills,
  useSelectedFormattedDrill
} from '../../hooks'
import { getRowId, Table } from '../../../../components/Table/Table'
import { useAppDispatch } from '../../../../store/hooks'
import { selectDrill } from '../../slice'
import BasicSelect from '../../../../components/Material/Select'
import { Button } from '../../../../components/Button/Button'
import {
  createNewDrill,
  startDrill,
  stopDrill,
  updateExistingDrill,
  duplicateDrill
} from '../../thunks'
import { useSelectedFormattedSession } from '../../../sessions/hooks'
import styles from './DrillsList.module.scss'
import { useTimeEvents } from '../../../events/time/hooks'
import { endTimeEvent } from '../../../events/actions'

export const DrillsList = () => {
  const dispatch = useAppDispatch()
  const { selectedId, drillCount } = useDrills()

  const formattedDrills = useFormattedDrills()
  const formattedDrill = useSelectedFormattedDrill()
  const activeFormattedDrill = useActiveFormattedDrill()
  const formattedSession = useSelectedFormattedSession()
  const { live } = formattedSession
  const { activeStoppage } = useTimeEvents()

  // Handle new drill name input //
  const [newDrillName, setNewDrillName] = useState(
    `Drill ${formattedDrills.length + 1}`
  )

  // Tag state //
  const [tag, setTag] = useState('')

  const baseHeaders = [
    { name: 'Name', key: 'drillName', width: 25 },
    { name: 'Tags', key: 'tags', width: 24 },
    { name: 'Start time', key: 'startTime', type: 'date', width: 15 },
    { name: 'End time', key: 'endTime', type: 'date', width: 15 },
    { name: 'Duration', key: 'duration', type: 'duration', width: 13 },
    { name: 'Players', key: 'playerCount', width: 9 }
  ]

  const startStopActionColumn = {
    name: '',
    key: 'action',
    type: 'icon',
    input: {
      type: 'icon',
      callback: (item) => {
        // Start a pending drill (if no other drill is active)
        if (item.isPending && !activeFormattedDrill && formattedSession.live) {
          dispatch(startDrill(item.id))
          return
        }

        // Stop an active drill
        if (item.isActive) {
          dispatch(stopDrill(item.id))
          if (activeStoppage) {
            dispatch(endTimeEvent(activeStoppage.id, { ...activeStoppage }))
          }
        }
      }
    },
    width: 7
  }

  const duplicateActionColumn = {
    name: '',
    key: 'duplicateAction',
    type: 'icon',
    input: {
      type: 'icon',
      callback: (item) => {
        dispatch(duplicateDrill(item.id))
      }
    },
    width: 7
  }

  // Create the final headers array
  const drillsTableHeaders = formattedSession.live
    ? [...baseHeaders, startStopActionColumn, duplicateActionColumn]
    : baseHeaders

  const drillsTableData = useMemo(() => {
    return formattedDrills.map((formattedDrill) => {
      let endTimeData: string | number = formattedDrill.endTime.unixMil
      if (formattedDrill.isActive) {
        endTimeData = 'Ongoing'
      } else if (formattedDrill.isPending) {
        endTimeData = '-'
      }

      // Action (play/stop) column icon and color
      let actionIcon = ''
      let actionColor = ''

      if (formattedDrill.isPending && formattedSession.live) {
        actionIcon = 'play'
        actionColor = '#00dd00'
        if (activeFormattedDrill) {
          actionColor = 'grey' // Disabled when another drill is active
        }
      } else if (formattedDrill.isActive) {
        actionIcon = 'stop'
        actionColor = '#ff0000'
      } else {
        actionIcon = '' // No action for finished drills
        actionColor = 'transparent'
      }

      // Duplicate action column
      let duplicateIcon = 'duplicate'
      let duplicateColor = '#0088ff'

      if (!formattedSession.live) {
        duplicateColor = ''
        duplicateIcon = ''
      }

      return {
        __tagColor: formattedDrill.color,
        __drillTagStyle: {
          whiteSpace: 'wrap'
        },
        id: formattedDrill.id,
        drillName: formattedDrill.name,
        tags: formattedDrill.rawData?.extraInfo?.tags?.join(', ') || '',
        startTime: formattedDrill.isStarted
          ? formattedDrill.startTime.unixMil
          : 'Pending',
        endTime: endTimeData,
        duration: {
          startTime: formattedDrill.startTime.unixSeconds,
          endTime: formattedDrill.endTime.unixSeconds,
          inputUnit: 'seconds',
          displayUnit: 'minutes',
          isActive: formattedDrill.isActive
        },
        playerCount: formattedDrill.drillPlayers.count,
        isPending: formattedDrill.isPending,
        isActive: formattedDrill.isActive,
        isFinished: formattedDrill.isFinished,
        action: {
          icon: actionIcon,
          color: actionColor
        },
        duplicateAction: {
          icon: duplicateIcon,
          color: duplicateColor
        }
      }
    })
  }, [formattedDrills])

  useEffect(() => {
    setNewDrillName(`Drill ${formattedDrills.length + 1}`)
  }, [formattedDrills])

  const handleAddTag = () => {
    if (!tag.trim()) {
      return
    }

    console.log('Current formatted drill:', formattedDrill)
    if (!formattedDrill?.rawData) {
      console.error('No raw drill data available')
      return
    }

    dispatch(
      updateExistingDrill({
        ...formattedDrill.rawData,
        extraInfo: {
          ...formattedDrill.rawData.extraInfo,
          tags: [...(formattedDrill.rawData.extraInfo?.tags || []), tag.trim()]
        }
      })
    )
    setTag('')
  }

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        padding: '10px',
        boxSizing: 'border-box'
      }}
    >
      {live && (
        <div
          style={{
            height: '70px'
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              columnGap: '20px'
            }}
          >
            <BasicSelect
              label={'Tag name'}
              variant='standard'
              selected={tag}
              onChange={(value) => setTag(value)}
              labelShrink={true}
              size={'small'}
              disabled={drillCount === 0}
            />
            <Button
              className='btn--primary'
              handleClick={handleAddTag}
              style={{
                marginTop: '5px',
                width: '100%',
                height: '40px'
              }}
              disabled={drillCount === 0}
            >
              Add Tag to Drill
            </Button>
            <div className={styles.headingSeparator} />
            <BasicSelect
              label={'New Drill Name'}
              variant='standard'
              selected={newDrillName}
              onChange={(value) => setNewDrillName(value)}
              labelShrink={true}
              size={'small'}
            />
            <Button
              className='btn--primary'
              handleClick={() => {
                dispatch(
                  createNewDrill({
                    name: newDrillName,
                    quickStart: false
                  })
                )
              }}
              style={{
                marginTop: '5px',
                width: '100%',
                height: '40px'
              }}
              disabled={!newDrillName.trim()}
            >
              {`Add ${newDrillName}`}
            </Button>
          </div>
        </div>
      )}
      <div
        style={{
          height: live ? 'calc(100% - 70px)' : '100%',
          paddingTop: !live ? '5px' : undefined
        }}
      >
        <Table
          options={{
            initialOrder: 'dec',
            initialSortBy: 'name',
            sortActive: true
          }}
          id='drillsTable'
          highlightRow={(tableId, item) => {
            dispatch(selectDrill(item.id))
          }}
          highlightedRow={getRowId(selectedId, 'drillsTable')}
          searchBy={['drillName']}
          checkBoxes={[
            {
              key: 'hidePending',
              label: 'Hide Pending',
              filter: (item) => {
                return !item.isPending
              }
            },
            {
              key: 'hideFinished',
              label: 'Hide Finished',
              filter: (item) => {
                return !item.isFinished
              }
            }
          ]}
          headerFont={14}
          smallHead={true}
          tableSize='large'
          tableClass={'minimalistBlack'}
          className={'container'}
          data={drillsTableData}
          headers={drillsTableHeaders}
        />
      </div>
    </div>
  )
}
