import React, { useState, useCallback, useRef, useEffect } from 'react'
import { DrillZoneStyles, GoalStyles } from './DrillZone'
import styles from './DrillZone.module.scss'

export type DraggableGoalProps = {
  drillZoneStyles: DrillZoneStyles
  goalStyles: GoalStyles
  handleGoalStylesUpdate: (goalStyle: GoalStyles) => void
  handleFullUpdate: () => void
  disabled: boolean
}

export const DraggableGoal = ({
  drillZoneStyles,
  goalStyles,
  handleGoalStylesUpdate,
  handleFullUpdate,
  disabled
}: DraggableGoalProps) => {
  const [dragging, setDragging] = useState(false)

  const startPost = useRef(null)

  const handleMouseDown = (e) => {
    if (disabled) return
    e.stopPropagation() // Prevent drill zone drag
    setDragging(true)
    startPost.current = {
      x: e.clientX,
      y: e.clientY
    }
  }

  const handleMouseMove = useCallback(
    (e) => {
      if (!dragging) return

      if (!startPost.current) return
      const dx = e.clientX - startPost.current.x
      const dy = e.clientY - startPost.current.y
      // Limit the movement within the drill zone bounds
      // const maxOffset = (drillZoneHeight - dimensions.width * scale) / 2
      // const limitedOffset = Math.max(
      //   -maxOffset,
      //   Math.min(maxOffset, newOffsetY)
      // )

      if (
        goalStyles.side.canvas === 'left' ||
        goalStyles.side.canvas === 'right'
      ) {
        const newY = goalStyles.y + dy
        // Check if the goal is within the drill zone bounds //
        if (
          newY < drillZoneStyles.y ||
          newY > drillZoneStyles.y + drillZoneStyles.height - goalStyles.height
        ) {
          return
        }
        handleGoalStylesUpdate({
          ...goalStyles,
          y: goalStyles.y + dy
        })
      } else if (
        goalStyles.side.canvas === 'top' ||
        goalStyles.side.canvas === 'bottom'
      ) {
        const newX = goalStyles.x + dx
        // Check if the goal is within the drill zone bounds //
        if (
          newX < drillZoneStyles.x ||
          newX > drillZoneStyles.x + drillZoneStyles.width - goalStyles.width
        ) {
          return
        }
        handleGoalStylesUpdate({
          ...goalStyles,
          x: goalStyles.x + dx
        })
      }
    },
    [dragging]
  )

  const handleMouseUp = useCallback(() => {
    if (dragging) {
      setDragging(false)
      handleFullUpdate()
    }
  }, [goalStyles])

  useEffect(() => {
    document.removeEventListener('mousemove', handleMouseMove)

    document.addEventListener('mousemove', handleMouseMove)
    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [handleMouseMove])

  useEffect(() => {
    document.removeEventListener('mouseup', handleMouseUp)

    document.addEventListener('mouseup', handleMouseUp)
    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [handleMouseUp])

  const gs = {
    right: null,
    borderRadius: null,
    position: 'absolute' as const,
    top: `${goalStyles.y}px`,
    left: `${goalStyles.x}px`,
    width: `${goalStyles.width}px`,
    height: `${goalStyles.height}px`,
    cursor: disabled ? 'not-allowed' : 'ns-resize'
  }

  return (
    <div
      className={`${styles.goal} ${styles.draggableGoal}`}
      style={gs}
      onMouseDown={handleMouseDown}
    />
  )
}
