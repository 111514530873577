import React from 'react'
import styles from './Scoreboard.module.scss'
import { useScoreboard, useSession } from '../../metrics_server/sessions/hooks'
import { TeamLogo } from '../TeamLogo/TeamLogo'

export interface ScoreboardProps {
  children: any
  sessionId: string
}

export const Scoreboard = ({ sessionId }) => {
  const { session } = useSession()

  const { scoreboard } = useScoreboard(sessionId)

  const teamA = session.teamsSessions[0].team
  const teamB = session.teamsSessions[1].team

  let teamAPoints = scoreboard.firstTeam.scoreSum
  let teamBPoints = scoreboard.secondTeam.scoreSum

  return (
    <div>
      <div className={styles.scoreboardContainer}>
        <TeamLogo logo={teamA.logo} color={teamA.color} size={25} />
        <div className={styles.scoreboardScore}>
          {' '}
          {teamAPoints} - {teamBPoints}
        </div>
        <TeamLogo logo={teamB.logo} color={teamB.color} size={25} />
      </div>
    </div>
  )
}
