import moment from '../../../utils/moment'
import React, { useEffect, useState } from 'react'
import { Action, ActionCreator } from 'redux'
import { CardList } from '../../../components/CardList/CardList'
import { Table } from '../../../components/Table/Table'
import { HardwareState } from '../../../metrics_server/hardware/types'
import { UserState } from '../../../metrics_server/user/types'
import styles from './SlowSensor.module.scss'
import { getDeviceStateColour } from '../../../const'

export interface SlowSensorProps {
  active: boolean
  activeTabKey: string
  toggleModal: (arg0: any) => void
  selectedTab: string
  selectedTabKey: string
  updateActiveTab: (tabName: string) => void
  user: UserState
  setOptions: ActionCreator<Action>
  hardware: HardwareState
}

export function SlowSensor(props: SlowSensorProps) {
  const {
    active,
    toggleModal,
    selectedTab,
    updateActiveTab,
    user,
    setOptions,
    hardware
  } = props

  const [isMounted, setIsMounted] = useState(false)
  const [highlightedRow, setHighlightedRow] = useState(null)

  useEffect(() => {
    if (isMounted) {
      if (active) {
        updateActiveTab(selectedTab)
      }
    } else {
      setIsMounted(true)
    }
  }, [selectedTab])

  const getTableData = (hardware: HardwareState) => {
    let data = []
    for (let id in hardware.diagnostics.hardwareState) {
      let device = hardware.diagnostics.hardwareState[id]
      let appUptime = new Date(device.slowSensors.appUptime * 1000)
        .toISOString()
        .substr(11, 8)
      let uwbUptime = new Date(device.slowSensors.uwbUptime * 1000)
        .toISOString()
        .substr(11, 8)

      let time = moment(device.lastOnline * 1000).format('H:mm:ss')

      let row = {
        id,
        encodedId: device.serial,
        battery: device.slowSensors.battery,
        avgCurrent: device.slowSensors.avgCurrent,
        appUptime: device.slowSensors.appUptime * 1000,
        uwbUptime: device.slowSensors.uwbUptime * 1000,
        dwTemp: device.slowSensors.dwTemp,
        mcuTemp: device.slowSensors.mcuTemp,
        timestamp: time,
        appFwVersion: device.appFwVersion,
        uwbFwVersion: device.uwbFwVersion,
        highlightColor: getDeviceStateColour(
          device,
          hardware.diagnostics.status
        ),
        voltage: device.slowSensors.voltage,
        reports: device.reports
      }
      data.push(row)
    }
    return data
  }

  const getTableHeaders = (hardware) => {
    return [
      { name: 'ID', key: 'id' },
      { name: 'Crockford ID', key: 'encodedId' },
      { name: 'Battery', key: 'battery', type: 'number', decimal: 0 },
      { name: 'Avg Current', key: 'avgCurrent', type: 'number', decimal: 0 },
      { name: 'Voltage', key: 'voltage', type: 'number', decimal: 2 },
      { name: 'App Uptime', key: 'appUptime', type: 'time' },
      { name: 'UWB Uptime', key: 'uwbUptime', type: 'time' },
      { name: 'DW Temp', key: 'dwTemp', type: 'number', decimal: 2 },
      { name: 'MCU Temp', key: 'mcuTemp', type: 'number', decimal: 2 },
      { name: 'Last Online', key: 'timestamp' },
      { name: 'AppMcu Version', key: 'appFwVersion' },
      { name: 'UwbMcu Version', key: 'uwbFwVersion' }
    ]
  }

  return (
    <React.Fragment>
      <div className={styles.tableContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className='card-table-container'>
            <Table
              options={{
                initialOrder: 'dec',
                initialSortBy: 'id',
                sortActive: true
              }}
              highlightRow={(id) => setHighlightedRow(id)}
              highlightedRow={highlightedRow}
              headerFont={13}
              tableClass={'minimalistBlack'}
              className={'container'}
              data={getTableData(hardware)}
              headers={getTableHeaders(hardware)}
            />
          </div>
        </CardList>
      </div>
    </React.Fragment>
  )
}
