import React, { Component } from 'react'
import { connect } from 'react-redux'
import { WebSocketController } from '../utils/websockets'
import { WS_ROOT_URL } from '../const'
import { MSServerHOC } from './MSServerHOC'
import { encodeHardwareId } from '../utils/encoding'

import { ConfirmationRequest } from '../components/ConfirmationRequest/ConfirmationRequest'

import * as teamActions from '../metrics_server/teams/actions'
import * as organisationActions from '../metrics_server/organisations/actions'
import * as sessionActions from '../metrics_server/sessions/actions'
import * as subSessionActions from '../metrics_server/sub_sessions/actions'
import { insightsActions } from '../metrics_server/insights'
import * as userActions from '../metrics_server/user/actions'
import * as hardwareActions from '../metrics_server/hardware/actions'
import * as routerActions from '../ui/router/actions'
import * as modalActions from '../ui/modal/actions'
import * as authenticationActions from '../ui/authentication/actions'
import * as notificationsActions from '../metrics_server/notifications/actions'
import * as dbActions from '../metrics_server/db/db'
import * as eventActions from '../metrics_server/events/actions'

// Types
import { UserState } from '../metrics_server/user/types'
import { OrganisationsState } from '../metrics_server/organisations/types'
import { AuthenticationState } from '../ui/authentication/types'
import { TeamsState } from '../metrics_server/teams/types'
import { SportscasterState } from '../metrics_server/sportscaster/types'
import { MqttContext } from '../metrics_server/mqtt/Provider'
import { EventsState } from '../metrics_server/events/types'
import { targetActions } from '../metrics_server/targets'
import { AppName } from './types'
import { VersionState } from '../metrics_server/version/types'
import { isLocal } from '../metrics_server/env'
import { broadcastingActions } from '../metrics_server/broadcasting'
import { sportscasterActions } from '../metrics_server/sportscaster'
import { removeBroadcastIntegrationData } from '../metrics_server/broadcast_integration/slice'

const actions = {
  ...teamActions,
  ...organisationActions,
  ...userActions,
  ...hardwareActions,
  ...routerActions,
  ...modalActions,
  ...authenticationActions,
  ...insightsActions,
  ...notificationsActions,
  ...sessionActions,
  ...dbActions,
  ...eventActions,
  ...targetActions,
  ...subSessionActions,
  ...broadcastingActions,
  ...sportscasterActions,
  removeBroadcastIntegrationData
}

export interface MSContainerProps {
  // UI
  authentication: AuthenticationState

  // MS
  user: UserState
  version: VersionState
  organisations: OrganisationsState
  teams: TeamsState
  sportscaster: SportscasterState
  events: EventsState

  // Actions need typing
  setTeam
  getTeams
  getOrganisations
  signoutUser
  toggleModal
  setRedirect
  removeUserData
  updateHardware
  setIsSignedIn

  getPitchConfig
  getSportscasterStatus
  getSportscasterVersions
  getSportscasterConfig
  clearSportscaster
  removeBroadcastIntegrationData

  checkActiveSession

  getInsightsLayouts
  getInsightsConfigs

  addNotification

  // MQTT actions and objects
  updateFlight
  getSessionFlights
  getFlight
  getEvent
  switchTeamSides
  getFullTarget
  getSessionTimeEvents
  getSessionGameEvents
  getFilteredSessions
  getBroadcastState
  targets
  sessions
  updateSelectedSession
  getPenaltyCountdownState
  getSeatSwappingAlgoState
  getSeatConfiguration
}

export const MSUserHOC: any = (ChildComponent) => {
  class ComposedComponent extends Component<MSContainerProps> {
    static contextType = MqttContext

    private mqttContextRef = null

    private healthCheckInterval: number

    private seatSwapStatePollInterval: number
    private seatConfigurationPollInterval: number

    constructor(props) {
      super(props)

      this.state = {
        orgData: false,
        teamData: false
      }

      // this.mqttContextRef = React.createRef()
      this.mqttContextRef = null
    }

    // Our component just got rendered
    componentDidMount() {
      const { sportscaster, user, version } = this.props
      const isMatchTracker = this.isAppType(AppName.matchTracker)
      const isCommentator = this.isAppType(AppName.commentatorTool)

      if (user.data.id) {
        this.getUserData()

        if (isMatchTracker && isLocal) {
          this.props.getSportscasterConfig(true)

          // Seat Swapping //
          if (version.app.Algorithms?.SeatSwapping?.Enabled) {
            this.startSeatSwapStatusPoll()
          }

          this.startSeatConfigurationPoll()
        } else if (isCommentator) {
          this.props.getSportscasterConfig(false)
        }

        if (isLocal) {
          this.props.getBroadcastState()
        }

        // TODO: Needs work
        if (isMatchTracker && isLocal) {
          this.startSportscasterStatusPoll()
        }

        if (sportscaster.selectedBrokerHost) {
          if (isCommentator) {
            this.props.getInsightsLayouts(user.data.id)
            this.props.getInsightsConfigs(user.data.id)
          }
        }
      } else {
        this.props.signoutUser()
        this.stopSportscasterStatusPoll()
        this.stopSeatswapStatusPoll()
        this.stopSeatswapConfigurationPoll()
      }
    }

    // Our component just got updated
    componentDidUpdate(prevProps, prevState) {
      const { organisations, sportscaster, user, version } = this.props
      const isMatchTracker = this.isAppType(AppName.matchTracker)
      const isCommentator = this.isAppType(AppName.commentatorTool)

      if (user.data.id && !prevProps.user.data.id) {
        if (isMatchTracker && isLocal) {
          this.props.getSportscasterConfig(true)

          if (version.app.Algorithms?.SeatSwapping?.Enabled) {
            this.startSeatSwapStatusPoll()
          }

          this.startSeatConfigurationPoll()
        } else if (isCommentator) {
          this.props.getSportscasterConfig(false)
        }

        if (isLocal) {
          this.props.getBroadcastState()
        }

        if (isCommentator) {
          this.props.getInsightsLayouts(user.data.id)
          this.props.getInsightsConfigs(user.data.id)
        }

        this.getUserData()

        if (isMatchTracker && isLocal) {
          this.startSportscasterStatusPoll()
        }
      } else if (!user.data.id && prevProps.user.data.id) {
        this.removeUserData()
        if (isMatchTracker) {
          this.stopSportscasterStatusPoll()
          this.stopSeatswapStatusPoll()
          this.stopSeatswapConfigurationPoll()
        }
      }

      // If organisation is created fetch user data
      if (
        user.data.id &&
        prevProps.organisations.hasValidData &&
        !organisations.hasValidData
      ) {
        this.getUserData()
      }

      const mqttClient = this.context[sportscaster.selectedBrokerHost]

      if (mqttClient && !this.mqttContextRef && user.data.id) {
        this.subscribeToUpdates(mqttClient)
      } else if (mqttClient && this.mqttContextRef && !user.data.id) {
        this.unsubscribeFromUpdates(mqttClient)
      }
    }

    subscribeToUpdates = (mqttClient) => {
      mqttClient.subscribe('metrics_server/ui_updates', (message) => {
        this.handleUIUpdate(message, mqttClient)
      })

      this.mqttContextRef = mqttClient
    }

    unsubscribeFromUpdates = (mqttClient) => {
      mqttClient.unsubscribe('metrics_server/ui_updates', (err) => {
        if (err) {
          console.error(
            'Failed to unsubscribe from metrics_server/ui_updates:',
            err
          )
        }
      })

      this.mqttContextRef = null
    }

    handleUIUpdate(updateData, mqttClient) {
      const { selected: selectedTarget } = this.props.targets
      const { filter } = this.props.sessions
      const isSelectedSession =
        updateData.sessionId === this.props.sessions.selected.id

      console.log('=====UPDATE DATA=====', updateData)

      if (updateData.action === 'DELETE') {
        switch (updateData.type) {
          case 'session':
            if (isSelectedSession) {
              this.props.updateSelectedSession(updateData.sessionId)
            }
            this.props.checkActiveSession()
            this.props.getFilteredSessions(filter.options)

            // Clear Sportscaster Events
            this.props.removeBroadcastIntegrationData()
            this.props.clearSportscaster()
            break
          case 'broadcast-PenaltyCountDown':
            this.props.getPenaltyCountdownState()
            break
          default:
            break
        }
      }

      if (updateData.action === 'UPDATE') {
        switch (updateData.type) {
          case 'flight':
            if (this.props.events.rawData[updateData.id]) {
              this.props.getFlight(updateData.id)
            }
            break
          case 'game_event':
            if (this.props.events.rawData[updateData.id]) {
              this.props.getEvent(updateData.id)
            }
            break
          case 'aussie_rules_event':
            if (this.props.events.rawData[updateData.id]) {
              this.props.getEvent(updateData.id)
            }
            break
          case 'session':
            if (isSelectedSession) {
              console.log('session update')
              this.props.updateSelectedSession(updateData.sessionId)
            }
            console.log('session update')
            this.props.checkActiveSession()
            this.props.getFilteredSessions(filter.options)
            break
          case 'timing':
            if (isSelectedSession) {
              this.props.getSessionTimeEvents(
                updateData.sessionId,
                updateData.id
              )
            }
            break
          case 'broadcast-possession':
            this.props.getSessionGameEvents(updateData.sessionId)
            if (isSelectedSession) {
              this.props.getBroadcastState()
            }
            break
          case 'broadcast-':
          case 'broadcast-teams':
            if (isSelectedSession) {
              this.props.getBroadcastState()
            }
            break
          case 'target':
            if (updateData.id === selectedTarget.selectedTarget) {
              this.props.getFullTarget(
                updateData.id,
                selectedTarget.selectedTarget
              )
            }
            break
          case 'broadcast-broadcastEnabled':
          case 'broadcast-broadcastDisabled':
            this.props.getBroadcastState()
            break
          default:
            break
        }
      }
    }

    isAppType = (appName) => {
      return this.props.version.uiType.name === appName
    }

    getUserData = () => {
      const { teams, version } = this.props
      const isMatchTracker = version.uiType.name === AppName.matchTracker
      // Get all teams and players and organisations
      this.props.getOrganisations((organisations) => {
        if (organisations.length > 0) {
          this.props.getTeams((data) => {
            if (data.coach[0] && !teams.selectedTeam) {
              this.props.setTeam(data.coach[0].id)
            }
          })
        }
      })

      this.enableNotifications()

      if (isMatchTracker && isLocal) {
        this.props.getPitchConfig()
        this.props.checkActiveSession()
        this.initialiseHardwareListener()
        this.props.getBroadcastState()
      }
    }

    startSportscasterStatusPoll = () => {
      this.healthCheckInterval = window.setInterval(() => {
        this.props.getSportscasterStatus()
        this.props.getSportscasterVersions()
      }, 2000)
    }

    stopSportscasterStatusPoll = () => {
      clearInterval(this.healthCheckInterval)
    }

    startSeatSwapStatusPoll = () => {
      this.seatSwapStatePollInterval = window.setInterval(() => {
        this.props.getSeatSwappingAlgoState()
      }, 2000)
    }

    startSeatConfigurationPoll = () => {
      this.seatConfigurationPollInterval = window.setInterval(() => {
        this.props.getSeatConfiguration()
      }, 2000)
    }

    stopSeatswapStatusPoll = () => {
      clearInterval(this.seatSwapStatePollInterval)
    }
    stopSeatswapConfigurationPoll = () => {
      clearInterval(this.seatConfigurationPollInterval)
    }

    // handleNoOrganisation = () => {
    //   this.props.toggleModal({
    //     active: true,
    //     type: 'confirm',
    //     handleProceed: () => {
    //       this.props.toggleModal({ active: false })
    //       this.props.setRedirect('/createorganisation')
    //     },
    //     ChildComponent: ConfirmationRequest,
    //     message:
    //       'You are currently not a part of an organisation. Returning to setup...',
    //     className: 'modalSmall',
    //     hideCancel: true
    //   })
    // }

    removeUserData = () => {
      this.props.removeUserData()
      WebSocketController.closeAllSockets()
    }

    enableNotifications = () => {
      WebSocketController.connectWebSocket(
        WS_ROOT_URL,
        'diagnostics/notifications',
        (data) => {
          this.props.addNotification(data)
        }
      )
    }

    initialiseHardwareListener = () => {
      WebSocketController.connectWebSocket(
        WS_ROOT_URL,
        'diagnostics',
        (data) => {
          // console.log(
          //   'online: ',
          //   data.status.online.balls?.map((id) => encodeHardwareId(id)) || null
          // )
          // console.log(
          //   'sleep: ',
          //   data.status.sleep.balls?.map((id) => encodeHardwareId(id)) || null
          // )
          // console.log(
          //   'offline: ',
          //   data.status.offline.balls?.map((id) => encodeHardwareId(id)) || null
          // )
          this.props.updateHardware({ ...data })
        }
      )
    }

    render() {
      return <ChildComponent {...this.props} />
    }
  }

  function mapStateToProps(state) {
    return {
      authentication: state.authentication,
      user: state.user,
      teams: state.teams,
      organisations: state.organisations,
      sportscaster: state.sportscaster,
      events: state.events,
      targets: state.targets
    }
  }

  return connect(mapStateToProps, actions)(MSServerHOC(ComposedComponent))
}
