import { AppRequest, executeAppRequest } from '../../utils/request_handler'
import { API_ROOT_URL } from '../api/config'
import { UnitSystemTypeValues } from '../units/types'

import {
  UpdatePlayerInDrillRequestBody,
  CreateDrillRequestBody,
  UpdateDrillRequestBody,
  RawDrill,
  RawPlayerBreakdowns
} from './types'

// Function to create a new drill
export const createDrill: AppRequest<CreateDrillRequestBody, RawDrill> = async (
  data
) => {
  const options = {
    url: `sessions/${data.sessionId}/drill`,
    data,
    method: 'post' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

export const getDrillsRequest: AppRequest<string, RawDrill[]> = async (
  sessionId
) => {
  const options = {
    url: `sessions/${sessionId}/drills`,
    method: 'get' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

/// Function to update a new drill
export const updateDrillRequest: AppRequest<
  UpdateDrillRequestBody,
  RawDrill
> = async (data) => {
  const options = {
    url: `/drill/${data.id}`,
    data,
    method: 'put' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// // Function to delete a drill
// export const deleteDrillRequest: AppRequest<
//   { sessionId: string; drillId: string },
//   { sessionId: string; drillId: string }
// > = async ({ sessionId, drillId }) => {
//   const options = {
//     url: `/sessions/${sessionId}/drills/${drillId}`,
//     method: 'delete' as const,
//     baseURL: API_ROOT_URL,
//     withCredentials: true
//   }
//   return executeAppRequest(options)
// }

// Function to add a player to a drill
export const addPlayerToDrillRequest: AppRequest<
  UpdatePlayerInDrillRequestBody,
  RawDrill
> = async (data) => {
  const options = {
    url: `/drill/${data.drillId}/player/${data.playerId}`,
    data: { bibNumber: data.bibNumber },
    method: 'put' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Function to remove a player from a drill
export const removePlayerFromDrillRequest: AppRequest<
  UpdatePlayerInDrillRequestBody,
  RawDrill
> = async (data) => {
  const options = {
    url: `/drill/${data.drillId}/player/${data.playerId}`,
    method: 'delete' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Function to set a player's bib number within a drill
export const setPlayerBibInDrillRequest: AppRequest<
  UpdatePlayerInDrillRequestBody,
  RawDrill
> = async (data) => {
  const options = {
    url: `/drill/${data.drillId}/player/${data.playerId}/bib/${data.bibNumber}`,
    data,
    method: 'put' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Start a drill
export const startDrillRequest: AppRequest<string, RawDrill> = async (id) => {
  const options = {
    url: `drill/${id}/start`,
    method: 'post' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Stop a drill
export const stopDrillRequest: AppRequest<string, RawDrill> = async (id) => {
  const options = {
    url: `drill/${id}/stop`,
    method: 'post' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Get player breakdown for a drill
export const getDrillPlayerBreakdownRequest: AppRequest<
  { drillId: string; unitSystemValue: UnitSystemTypeValues },
  RawPlayerBreakdowns
> = async ({ drillId, unitSystemValue }) => {
  const options = {
    url: `drill/${drillId}/player_breakdown?units=${unitSystemValue}`,
    method: 'get' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}
