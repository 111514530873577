import React from 'react'
import { CloudIcon } from '../../Icons/CloudIcon/CloudIcon'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import EditIcon from '@mui/icons-material/Edit'
import { TagIcon } from '../../Icons/TagIcon/TagIcon'
import InfoIcon from '@mui/icons-material/Info'
import Loader from '../../Loader/Loader'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import CopyAllIcon from '@mui/icons-material/CopyAll'

interface IconCellProps {
  type: 'cloud' | 'bin' | 'refresh' | 'edit' | 'tag' | 'info' | 'spinner'
  color: string
}

function getIcon(type, color) {
  switch (type) {
    case 'cloud':
      return <CloudIcon color={color} />
    case 'bin':
      return <DeleteOutlineIcon sx={{ width: '100%' }} color={color} />
    case 'refresh':
      return <CachedOutlinedIcon color={color} />
    case 'edit':
      return <EditIcon color={color} />
    case 'tag':
      return <TagIcon color={color} active={true} />
    case 'info':
      return <InfoIcon color={color} />
    case 'spinner':
      return <Loader small={true} color={color} />
    case 'play':
      return <PlayCircleIcon sx={{ color }} />
    case 'stop':
      return <StopCircleIcon sx={{ color }} color={color} />
    case 'duplicate':
      return <CopyAllIcon sx={{ color }} />
    default:
      return <noscript />
  }
}

export function IconCell({ type, color }: IconCellProps) {
  return (
    <div
      style={{
        width: '18px',
        height: '18px',
        display: 'flex',
        alignItems: 'center',
        margin: 'auto'
      }}
    >
      {getIcon(type, color)}
    </div>
  )
}
