import React, { useEffect, useState } from 'react'
import styles from '../styles.module.scss'
import Select from '../../Inputs/Select/Select'
import { useHardwareFilter } from '../../../../metrics_server/hardware/filter'
import { usePitches } from '../../../../metrics_server/pitches/hooks'
import { useAppDispatch } from '../../../../store/hooks'
import {
  updateSecondPitchTagList,
  updateTagList
} from '../../../../metrics_server/pitches/actions'
import { autosetupTagColours } from '../../../../metrics_server/pitches/const'

export const FullPitchTagListSetupForm = () => {
  const dispatch = useAppDispatch()
  const { setupTagList, secondPitchSetupTagList, doublePitchSetup } =
    usePitches()

  const { filteredHardware } = useHardwareFilter(
    {
      type: 'PlayerTag',
      assigned: 'all'
    },
    'setup'
  )

  const [firstPitchFirstTag, firstPitchSecondTag, firstPitchThirdTag] =
    setupTagList

  const [secondPitchFirstTag, secondPitchSecondTag, secondPitchThirdTag] =
    secondPitchSetupTagList

  const [firstPitchFirstTagListItem, setFirstTagListItem] =
    useState(firstPitchFirstTag)
  const [firstPitchSecondTagListItem, setSecondTagListItem] =
    useState(firstPitchSecondTag)
  const [firstPitchThirdTagListItem, setThirdTagListItem] =
    useState(firstPitchThirdTag)

  const [secondPitchFirstTagListItem, setSecondPitchFirstTagListItem] =
    useState(secondPitchFirstTag)
  const [secondPitchSecondTagListItem, setSecondPitchSecondTagListItem] =
    useState(secondPitchSecondTag)
  const [secondPitchThirdTagListItem, setSecondPitchThirdTagListItem] =
    useState(secondPitchThirdTag)

  const tagOptionsList = [
    firstPitchFirstTagListItem,
    firstPitchSecondTagListItem,
    firstPitchThirdTagListItem
  ]

  if (doublePitchSetup) {
    tagOptionsList.push(
      secondPitchFirstTagListItem,
      secondPitchSecondTagListItem,
      secondPitchThirdTagListItem
    )
  }

  const [uniqueOptionsList, setUniqueOptionsList] = useState(tagOptionsList)

  const [defaultOption] = filteredHardware.optionsWithNull

  useEffect(() => {
    const tagOptionsList = [
      firstPitchFirstTagListItem,
      firstPitchSecondTagListItem,
      firstPitchThirdTagListItem
    ]

    if (doublePitchSetup) {
      tagOptionsList.push(
        secondPitchFirstTagListItem,
        secondPitchSecondTagListItem,
        secondPitchThirdTagListItem
      )
    }

    setUniqueOptionsList(tagOptionsList)
  }, [
    firstPitchFirstTagListItem,
    firstPitchSecondTagListItem,
    firstPitchThirdTagListItem,
    secondPitchFirstTagListItem,
    secondPitchSecondTagListItem,
    secondPitchThirdTagListItem,
    doublePitchSetup
  ])

  const handleUpdateTagList = (index, name, value) => {
    function removeDuplicates() {
      if (value === secondPitchFirstTagListItem) {
        setSecondPitchFirstTagListItem(null)
        dispatch(updateSecondPitchTagList(index, null))
      }

      if (value === secondPitchSecondTagListItem) {
        setSecondPitchSecondTagListItem(null)
        dispatch(updateSecondPitchTagList(index, null))
      }

      if (value === secondPitchThirdTagListItem) {
        setSecondPitchThirdTagListItem(null)
        dispatch(updateSecondPitchTagList(index, null))
      }
    }

    if (name === 'firstPitchFirstTag') {
      setFirstTagListItem(value)
      dispatch(updateTagList(index, value))
      removeDuplicates()
    }

    if (name === 'firstPitchSecondTag') {
      setSecondTagListItem(value)
      dispatch(updateTagList(index, value))
      removeDuplicates()
    }

    if (name === 'firstPitchThirdTag') {
      setThirdTagListItem(value)
      dispatch(updateTagList(index, value))
      removeDuplicates()
    }

    if (name === 'secondPitchFirstTag') {
      setSecondPitchFirstTagListItem(value)
      dispatch(updateSecondPitchTagList(index, value))
    }

    if (name === 'secondPitchSecondTag') {
      setSecondPitchSecondTagListItem(value)
      dispatch(updateSecondPitchTagList(index, value))
    }

    if (name === 'secondPitchThirdTag') {
      setSecondPitchThirdTagListItem(value)
      dispatch(updateSecondPitchTagList(index, value))
    }
  }

  return (
    <>
      <div className={styles.selectContainer}>
        <h5>Pitch 1 tag list:</h5>
        <div className={styles.tagSelect}>
          <div
            style={{
              fontSize: '13px',
              color: autosetupTagColours.primary[0],
              textDecoration: 'underline',
              fontWeight: 700
            }}
          >
            1:
          </div>
          <div className='smallSelectContainer'>
            <Select
              className='small-select'
              defaultSelection={defaultOption}
              options={filteredHardware.optionsWithNull}
              selectedOptions={uniqueOptionsList}
              input={{
                name: 'firstPitchFirstTag',
                value: firstPitchFirstTagListItem
              }}
              updateValue={(name, value) => handleUpdateTagList(0, name, value)}
            />
          </div>
          <div
            style={{
              fontSize: '13px',
              color: autosetupTagColours.primary[1],
              textDecoration: 'underline',
              fontWeight: 700
            }}
          >
            2:
          </div>
          <div className='smallSelectContainer'>
            <Select
              className='small-select'
              defaultSelection={defaultOption}
              options={filteredHardware.optionsWithNull}
              selectedOptions={uniqueOptionsList}
              input={{
                name: 'firstPitchSecondTag',
                value: firstPitchSecondTagListItem
              }}
              updateValue={(name, value) => handleUpdateTagList(1, name, value)}
            />
          </div>
          <div
            style={{
              fontSize: '13px',
              color: autosetupTagColours.primary[2],
              textDecoration: 'underline',
              fontWeight: 700
            }}
          >
            3:
          </div>
          <div className='smallSelectContainer'>
            <Select
              className='small-select'
              defaultSelection={defaultOption}
              options={filteredHardware.optionsWithNull}
              selectedOptions={uniqueOptionsList}
              input={{
                name: 'firstPitchThirdTag',
                value: firstPitchThirdTagListItem
              }}
              updateValue={(name, value) => handleUpdateTagList(2, name, value)}
            />
          </div>
        </div>
      </div>
      {doublePitchSetup && (
        <div className={styles.selectContainer}>
          <h5>Pitch 2 tag list:</h5>
          <div className={styles.tagSelect}>
            <div
              style={{
                fontSize: '13px',
                color: autosetupTagColours.secondary[0],
                textDecoration: 'underline',
                fontWeight: 700
              }}
            >
              1:
            </div>
            <div className='smallSelectContainer'>
              <Select
                className='small-select'
                defaultSelection={defaultOption}
                options={filteredHardware.optionsWithNull}
                selectedOptions={uniqueOptionsList}
                input={{
                  name: 'secondPitchFirstTag',
                  value: secondPitchFirstTagListItem
                }}
                updateValue={(name, value) =>
                  handleUpdateTagList(0, name, value)
                }
              />
            </div>
            <div
              style={{
                fontSize: '13px',
                color: autosetupTagColours.secondary[1],
                textDecoration: 'underline',
                fontWeight: 700
              }}
            >
              2:
            </div>
            <div className='smallSelectContainer'>
              <Select
                className='small-select'
                defaultSelection={defaultOption}
                options={filteredHardware.optionsWithNull}
                selectedOptions={uniqueOptionsList}
                input={{
                  name: 'secondPitchSecondTag',
                  value: secondPitchSecondTagListItem
                }}
                updateValue={(name, value) =>
                  handleUpdateTagList(1, name, value)
                }
              />
            </div>
            <div
              style={{
                fontSize: '13px',
                color: autosetupTagColours.secondary[2],
                textDecoration: 'underline',
                fontWeight: 700
              }}
            >
              3:
            </div>
            <div className='smallSelectContainer'>
              <Select
                className='small-select'
                defaultSelection={defaultOption}
                options={filteredHardware.optionsWithNull}
                selectedOptions={uniqueOptionsList}
                input={{
                  name: 'secondPitchThirdTag',
                  value: secondPitchThirdTagListItem
                }}
                updateValue={(name, value) =>
                  handleUpdateTagList(2, name, value)
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
