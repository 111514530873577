import React, { useCallback } from 'react'
import styles from './DrillsQuickStart.module.scss'
import { timeEventTypes } from '../../../../metrics_server/events/time/data_types'
import { Button } from '../../../../components/Button/Button'
import { useSelectedFormattedSession } from '../../../../metrics_server/sessions/hooks'
import { useAppDispatch } from '../../../../store/hooks'
import {
  createNewDrill,
  stopDrill
} from '../../../../metrics_server/drills/thunks'
import { createTimeEvent, endTimeEvent } from '../../../events/actions'
import {
  useActiveFormattedDrill,
  useDrills
} from '../../../../metrics_server/drills/hooks'
import { useTimeEvents } from '../../../events/time/hooks'

export function DrillsQuickStart() {
  const dispatch = useAppDispatch()

  const { loading, drillCount } = useDrills()

  const formattedSession = useSelectedFormattedSession()
  const { live } = formattedSession

  const activeFormattedDrill = useActiveFormattedDrill()

  const { activeStoppage } = useTimeEvents()

  const handleDrillQuickStart = async () => {
    dispatch(
      createNewDrill({
        name: `Drill ${drillCount + 1} - Quick Start`,
        quickStart: true
      })
    )
  }

  const handleStopDrill = useCallback(() => {
    if (activeFormattedDrill?.id) {
      dispatch(stopDrill(activeFormattedDrill.id))
      if (activeStoppage) {
        dispatch(endTimeEvent(activeStoppage.id, { ...activeStoppage }))
      }
    }
  }, [activeFormattedDrill?.id, activeStoppage, dispatch])

  const handlePauseDrill = () => {
    dispatch(
      createTimeEvent({
        sessionId: formattedSession.id,
        type: timeEventTypes.items.stoppage.value
      })
    )
  }

  const handleResumeDrill = useCallback(() => {
    dispatch(endTimeEvent(activeStoppage.id, { ...activeStoppage }))
  }, [activeStoppage])

  if (!live) {
    return null
  }

  return (
    <div className={styles.container}>
      <Button
        className='btn--border btn--thin btn--small'
        handleClick={activeStoppage ? handleResumeDrill : handlePauseDrill}
        style={{
          width: '75px',
          backgroundColor: activeStoppage
            ? timeEventTypes.items.stoppage.props.features.buttons.stop.colour
            : timeEventTypes.items.stoppage.props.features.buttons.start.colour
        }}
        loading={loading}
      >
        {activeStoppage ? 'Resume' : 'Pause'}
      </Button>
      <div className={styles.optionsBorder} />
      <Button
        className='btn--border btn--thin btn--small'
        handleClick={
          activeFormattedDrill ? handleStopDrill : handleDrillQuickStart
        }
        style={{
          backgroundColor: activeFormattedDrill
            ? timeEventTypes.items.stoppage.props.features.buttons.stop.colour
            : timeEventTypes.items.stoppage.props.features.buttons.start.colour
        }}
        loading={loading}
      >
        {activeFormattedDrill
          ? 'Stop Drill'
          : `Drill ${drillCount + 1} Quick Start`}
      </Button>
    </div>
  )
}
