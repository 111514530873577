import React, { useEffect } from 'react'
import styles from './SessionList.module.scss'
import { SessionsState } from '../../../metrics_server/sessions/types'
import { TeamsState } from '../../../metrics_server/teams/types'
import { DatePickerWrapper } from '../../../components/Forms/Fields/DatePickerWrapper/DatePickerWrapper'
import { SearchableDropdown } from '../../../components/SearchableDropdown/SearchableDropdown'
import { SportableBall } from '../../../components/Icons/SportableBall/SportableBall'
import { OrganisationsState } from '../../../metrics_server/organisations/types'
import { AppConfig } from '../../../App/App.types'
import BasicSelect from '../../../components/Material/Select'
import { useSessions } from '../../../metrics_server/sessions/hooks'
import moment from '../../../utils/moment'
import { CardList } from '../../../components/CardList/CardList'
import SessionCard from '../../../components/SessionList/SessionCard/SessionCard'
import { setActivityListScrollHeight } from '../../../metrics_server/sessions/actions'
import { useAppDispatch } from '../../../store/hooks'
import { useSessionFilter } from '../../../metrics_server/sessions/filter'
import { useOrganisations } from '../../../metrics_server/organisations/hooks'
import { isPropEmpty } from '../../../utils/helpers'
import { setRedirect } from '../../../ui/router/actions'
import { useAppTypeCheck } from '../../../metrics_server/user/hooks'

interface SessionListProps {
  sessions: SessionsState
  teams: TeamsState
  organisations: OrganisationsState

  // actions
  getSession
  updateSessionFilter
  updateLocalSessionFilter
  toggleEditModal
  toggleDownloadModal
  setActivityListScrollHeight
  setRedirect

  // container props
  sortedSessions
  animationComplete
  appConfig: AppConfig
}

export const SessionList: React.FC<SessionListProps> = (props) => {
  const dispatch = useAppDispatch()

  const sessions = useSessions()
  const organisations = useOrganisations()

  const appTypeCheck = useAppTypeCheck()

  const { sortedSessions, filters, updateFilterValue } = useSessionFilter()
  const { animationComplete } = props
  const updateDate = (name, date) => {
    if (!date) {
      console.error('No date provided', name, date)
    }
    if (name === 'startTime') {
      const newdate = moment(date)
      date = newdate.startOf('day').valueOf() / 1000
    } else {
      const newdate = moment(date)
      date = newdate.endOf('day').valueOf() / 1000
    }
    updateFilterValue(name, date)
  }

  useEffect(() => {
    if (organisations.fetched && animationComplete) {
      if (isPropEmpty(organisations.items))
        dispatch(setRedirect('/createorganisation'))
    }
  }, [organisations.fetched, animationComplete])

  const startTimeSessionDates = sessions.filter.items.map((item) => {
    return { startTime: item.startTime, sportType: item.sportType }
  })

  const endTimeSessionDates = sessions.filter.items.map((item) => {
    return { endTime: item.endTime, sportType: item.sportType }
  })

  useEffect(() => {
    if (appTypeCheck.isExternalSoccerTraining) {
      updateFilterValue('sportType', 'Soccer')
    }
  }, [])

  return (
    <div style={{ height: '100%', width: '100%', position: 'absolute' }}>
      <div className={styles.dropDownSpaceBetween}>
        <div className={styles.filterOptionsContainer}>
          <div className={`${styles.filterSelect}`}>
            <BasicSelect
              label={'State'}
              variant='standard'
              selected={filters.state.value}
              options={filters.state.options}
              onChange={(value) => updateFilterValue('state', value)}
              size={'small'}
            />
          </div>

          <div className={`${styles.filterSelect}`}>
            <BasicSelect
              label={'Session Type'}
              variant='standard'
              selected={filters.type.value}
              options={filters.type.options}
              onChange={(value) => updateFilterValue('type', value)}
              size={'small'}
            />
          </div>
          {!appTypeCheck.isExternalSoccerTraining && (
            <div className={`${styles.filterSelect}`}>
              <BasicSelect
                label={'Sub Type'}
                variant='standard'
                selected={filters.subType.value}
                options={filters.subType.options}
                onChange={(value) => updateFilterValue('subType', value)}
                size={'small'}
              />
            </div>
          )}

          {!appTypeCheck.isExternalSoccerTraining && (
            <div className={`${styles.filterSelect}`}>
              <BasicSelect
                label={'Sport'}
                variant='standard'
                selected={filters.sportType.value}
                options={filters.sportType.options}
                onChange={(value) => updateFilterValue('sportType', value)}
                size={'small'}
              />
            </div>
          )}

          <div className={`${styles.filterSelect}`}>
            <BasicSelect
              label={'Name or ID'}
              variant='standard'
              selected={filters.searchStr.value}
              onChange={(value) => updateFilterValue('searchStr', value)}
              labelShrink={true}
              size={'small'}
            />
          </div>
          {/* <div className={`${styles.filterSelect}`}>
              <div className={styles.smallLabel}>Type:</div>
              <div style={{width: '-webkit-fill-available'}}>
                <Select className="small-select" options={sessionTypeOptions} input={{name: 'type', value: filter.options.type}} updateValue={updateLocalSessionFilter}/>
              </div>
            </div> */}
          <div className={`${styles.filterSelect}`}>
            <SearchableDropdown
              items={filters.team.options}
              size='small'
              onSelect={(value) => updateFilterValue('team', value)}
              value={filters.team.value}
              label='Team'
              disabledValue={filters.team.value}
              isMultiple={false}
              noOptionsText='No Team Found'
              inputVariant='standard'
            />
          </div>
          <div className={`${styles.filterSelect} datePicker`}>
            <DatePickerWrapper
              value={new Date(filters.startTime.value * 1000)}
              onChange={(value) =>
                value ? updateDate('startTime', value) : ''
              }
              label='From'
              max={new Date(filters.endTime.value * 1000)}
              sessionDays={startTimeSessionDates}
              Icon={SportableBall}
            />
          </div>
          <div className={`${styles.filterSelect} datePicker`}>
            <DatePickerWrapper
              value={new Date(filters.endTime.value * 1000)}
              onChange={(value) => (value ? updateDate('endTime', value) : '')}
              label='To'
              min={new Date(filters.startTime.value * 1000)}
              sessionDays={endTimeSessionDates}
              Icon={SportableBall}
            />
          </div>
        </div>
      </div>
      <div className={styles.sessionListContainer}>
        <CardList
          padding={7.5}
          col={12}
          items={sortedSessions}
          scrollerId={`session-scroller`}
          initialScrollHeight={sessions.activityListScrollHeight}
          saveScrollHeight={(scrollHeight: number) => {
            dispatch(setActivityListScrollHeight(scrollHeight))
          }}
        >
          <SessionCard />
        </CardList>
      </div>
    </div>
  )
}
