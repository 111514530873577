import React, { useEffect, useRef, useState } from 'react'
import styles from './TargetDropZone.module.scss'
import { FormattedPlayerSession } from '../../metrics_server/sessions/types'
import { Target } from '../../metrics_server/targets/types'
import { useSelectedFormattedSession } from '../../metrics_server/sessions/hooks'

export interface TargetDropZoneProps {
  target: Target
  playerIds: string[]
}

export const TargetDropZone = ({ target, playerIds }: TargetDropZoneProps) => {
  const formattedSession = useSelectedFormattedSession()

  const [scale, setScale] = useState(null)
  const container = useRef(null)

  const getScale = (maxRadius) => {
    if (container.current) {
      const containerWidth = parseFloat(
        container.current.getBoundingClientRect().width
      )
      const containerHeight = parseFloat(
        container.current.getBoundingClientRect().height
      )
      const width =
        containerWidth > containerHeight ? containerHeight : containerWidth
      return width / 2 / maxRadius
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (target.radii) {
        setScale(getScale(target.radii[2]))
      }
    }, 0)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (target.radii) {
        setScale(getScale(target.radii[2]))
      }
    }, 0)
  }, [target])

  const renderPlayerKicks = (
    playerSession: FormattedPlayerSession,
    target: Target,
    scale: number
  ) => {
    if (!target.targetSummary?.[playerSession.id]) {
      return <noscript />
    }

    if (playerIds.indexOf(playerSession.playerId) === -1) return

    const { flightStats } = target.targetSummary[playerSession.id]

    if (flightStats) {
      return flightStats.map((flightStat) => {
        const dx = flightStat.x - target.x
        const dy = flightStat.y - target.y
        return (
          <div
            key={flightStat.id}
            style={{ bottom: dy * scale, left: dx * scale }}
            className={styles.kickDotContainer}
          >
            <div
              style={{ background: playerSession.player.color }}
              className={styles.kickDot}
            ></div>
          </div>
        )
      })
    }
    return null
  }

  const renderTarget = (width: number, i: number) => {
    let color: string
    switch (i) {
      case 0:
        color = 'rgba(244, 247, 99, 0.5)'
        break
      case 1:
        color = 'rgba(100,0,0,0.5)'
        break
      case 2:
        color = 'rgba(0,0,100,0.5)'
        break
      default:
        break
    }
    return (
      <div
        key={i}
        style={{ width, height: width, background: color }}
        className={styles.targetCircle}
      ></div>
    )
  }

  const getScaledWidths = (scale: number, radii: number[]) => {
    return radii.map((radius) => {
      return radius * scale * 2
    })
  }

  const radii = target.radii
  let scaledWidths
  if (radii && scale) {
    scaledWidths = getScaledWidths(scale, radii)
  }

  return (
    <div ref={container} className={styles.container}>
      {scaledWidths && (
        <>
          {scaledWidths.map(renderTarget)}
          <div className={styles.flightsContainer}>
            {formattedSession.playersSessions.byPlayerId.list.map(
              (playerSession) => renderPlayerKicks(playerSession, target, scale)
            )}
          </div>
        </>
      )}
    </div>
  )
}
