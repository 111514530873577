import { formatMetrics } from '../metrics/functions'
import { getEmptyGroup } from '../sessions/functions'
import { FormattedSession } from '../sessions/types'
import { addItemToGroup } from '../types'
import { UnitSystem } from '../units/types'
import {
  BibTeam,
  RawDrill,
  FormattedBibTeam,
  FormattedDrill,
  FormattedDrillPlayer,
  FormattedPlayerBreakdown,
  FormattedPlayerBreakdowns,
  RawPlayerBreakdowns,
  ProcessedDrill
} from './types'
import { Sport, sportTypes } from '../sports/data_types'
import { timestampToFomattedDate } from '../../utils/helpers'
import { PitchCoordinates } from '../pitches/types'

export type MetricValues<MetricTypeItems> = {
  [k in keyof MetricTypeItems]?: string | number | boolean
}

// Function to process the raw drill data from the server //
export const processDrill = (
  drill: RawDrill,
  formattedSession: FormattedSession,
  color: string
): ProcessedDrill => {
  return {
    rawData: drill,
    id: drill.id,
    name: drill.name,
    type: drill.type,
    sessionId: drill.sessionId,
    startTime: timestampToFomattedDate(drill.startTime),
    endTime: timestampToFomattedDate(drill.timeEnd),
    duration: drill.timeEnd - drill.startTime,
    sessionStartTime: drill.startTime
      ? drill.startTime - formattedSession.startTime.unixSeconds
      : null,
    sessionEndTime: drill.timeEnd
      ? drill.timeEnd - formattedSession.startTime.unixSeconds
      : null,
    extraInfo: drill.extraInfo,
    playerBibs: drill.playerBibs,
    color,

    isStarted: !!drill.startTime,
    isActive: !!drill.startTime && !drill.timeEnd && formattedSession.live,
    isFinished: !!drill.timeEnd || !formattedSession.live,
    isPending: !drill.startTime && !drill.timeEnd,

    region: drill.extraInfo.region,
    goalsOrPostsEnabled:
      !!drill.extraInfo.region?.P5?.x ||
      !!drill.extraInfo.region?.P5?.y ||
      !!drill.extraInfo.region?.P5?.z
  }
}

// Format the bib team for display in the UI
export const formatBibTeam = (
  rawBibTeam: BibTeam,
  bibId: number
): FormattedBibTeam => {
  return {
    rawData: rawBibTeam,
    id: bibId,
    name: rawBibTeam?.name,
    colour: rawBibTeam?.colour
  }
}

// Format the drill for display in the UI
export const formatDrill = (
  drill: ProcessedDrill,
  formattedSession: FormattedSession
): FormattedDrill => {
  if (!drill) {
    return null
  }
  const drillPlayers = getEmptyGroup<FormattedDrillPlayer>()
  for (const playerId in drill.playerBibs) {
    const player = formattedSession.players.all.map[playerId]

    const bibId = drill.playerBibs[playerId]
    const rawBibTeam = formattedSession.sport.props.features.bibs?.[bibId]
    const bib = formatBibTeam(rawBibTeam, bibId)
    if (player) {
      addItemToGroup(
        drillPlayers,
        { playerId, player, bib },
        playerId,
        'playerId',
        player.nameAndNumber
      )
    }
  }

  const bibs = getEmptyGroup<FormattedBibTeam, number>()

  for (const bibId in formattedSession.sport.props.features.bibs) {
    const rawBibTeam = formattedSession.sport.props.features.bibs?.[bibId]
    const bib = formatBibTeam(rawBibTeam, parseInt(bibId))
    addItemToGroup(bibs, bib, bibId, 'id', bib.name)
  }

  const bibDirection = drill.extraInfo.bibTeamDirection
    ? drill.extraInfo.bibTeamDirection.map((bibId) => bibs.map[bibId])
    : null

  return {
    ...drill,
    drillPlayers,
    bibs,
    bibDirection
  }
}

// Format player breakdown for display in the UI
export const formatPlayerBreakdown = (
  rawPlayerBreakdowns: RawPlayerBreakdowns,
  formattedSession: FormattedSession,
  unitSystem: UnitSystem
): FormattedPlayerBreakdowns => {
  // Map the raw player breakdown metrics to the UI metric keys
  let totalMetrics: MetricValues<
    typeof formattedSession.playerSummaryMetricTypes.items
  > = {}

  // Map the raw player breakdown metrics to the UI metric keys
  if (formattedSession.sport.value === sportTypes.items.soccer.value) {
    const involvedInCarries =
      parseInt(rawPlayerBreakdowns.totals.dribbling.total) > 0
    totalMetrics = {
      totalPasses: rawPlayerBreakdowns.totals.passes.allPasses.total,
      totalPassCompletion:
        rawPlayerBreakdowns.totals.passes.allPasses.completion.total,
      totalForwardPasses: rawPlayerBreakdowns.totals.passes.forwardPasses.total,
      totalBackwardPasses:
        rawPlayerBreakdowns.totals.passes.backwardPasses.total,
      totalSidewaysPasses:
        rawPlayerBreakdowns.totals.passes.sidewaysPasses.total,
      totalShotsAtGoal: rawPlayerBreakdowns.totals.shooting.total,
      averageRecycleTime: involvedInCarries
        ? rawPlayerBreakdowns.totals.dribbling.averageRecycleTime.value
        : null,
      totalGoalsScored: rawPlayerBreakdowns.totals.shooting.totalGoals
    }
  } else if (
    formattedSession.sport.value === sportTypes.items.americanFootball.value
  ) {
    const involvedInPasses =
      parseInt(rawPlayerBreakdowns.totals.passes.totalInvolvements) > 0
    totalMetrics = {
      totalPasses: rawPlayerBreakdowns.totals.passes.allPasses.total,
      totalPassCompletion:
        rawPlayerBreakdowns.totals.passes.allPasses.completion.total,
      averageThrowDistance: involvedInPasses
        ? rawPlayerBreakdowns.totals.passes.allPasses.averageDistance.value
        : null,
      averageSpeed: involvedInPasses
        ? rawPlayerBreakdowns.totals.passes.allPasses.averageSpeed.value
        : null,
      averageSpin: involvedInPasses
        ? rawPlayerBreakdowns.totals.passes.allPasses.averageSpin.value
        : null,
      averageEfficiency: involvedInPasses
        ? rawPlayerBreakdowns.totals.passes.allPasses.averageEfficiency?.value
        : null
    }
  }

  // Format the total metrics
  const formattedTotalMetrics = formatMetrics(
    formattedSession.playerSummaryMetricTypes,
    totalMetrics,
    formattedSession,
    unitSystem
  )

  // Format the player breakdown metrics
  const formattedPlayerBreakdowns = getEmptyGroup<FormattedPlayerBreakdown>()

  for (const key in rawPlayerBreakdowns.players) {
    const playerBreakdown = rawPlayerBreakdowns.players[key]

    const formattedPlayer: FormattedPlayerBreakdown = {
      id: key,
      player: formattedSession.players.all.map[key],
      metrics: null
    }

    let playerMetrics: MetricValues<
      typeof formattedSession.playerSummaryMetricTypes.items
    > = {}

    if (formattedSession.sport.value === sportTypes.items.soccer.value) {
      const involvedInCarries = parseInt(playerBreakdown.dribbling.total) > 0
      playerMetrics = {
        totalPasses: playerBreakdown.passes.allPasses.total,
        totalPassCompletion: playerBreakdown.passes.allPasses.completion.total,
        totalForwardPasses: playerBreakdown.passes.forwardPasses.total,
        totalBackwardPasses: playerBreakdown.passes.backwardPasses.total,
        totalSidewaysPasses: playerBreakdown.passes.sidewaysPasses.total,
        totalShotsAtGoal: playerBreakdown.shooting.total,
        averageRecycleTime: involvedInCarries
          ? playerBreakdown.dribbling.averageRecycleTime.value
          : null,
        totalGoalsScored: playerBreakdown.shooting.totalGoals
      }
    } else if (
      formattedSession.sport.value === sportTypes.items.americanFootball.value
    ) {
      const involvedInPasses =
        parseInt(playerBreakdown.passes.totalInvolvements) > 0
      playerMetrics = {
        totalPasses: playerBreakdown.passes.allPasses.total,
        totalPassCompletion: playerBreakdown.passes.allPasses.completion.total,
        averageThrowDistance: involvedInPasses
          ? playerBreakdown.passes.allPasses.averageDistance.value
          : null,
        averageSpeed: involvedInPasses
          ? playerBreakdown.passes.allPasses.averageSpeed.value
          : null,
        averageSpin: involvedInPasses
          ? playerBreakdown.passes.allPasses.averageSpin.value
          : null,
        averageEfficiency: involvedInPasses
          ? playerBreakdown.passes.allPasses.averageEfficiency?.value
          : null
      }
    }

    const formattedPlayerMetrics = formatMetrics(
      formattedSession.playerSummaryMetricTypes,
      playerMetrics,
      formattedSession,
      unitSystem
    )

    formattedPlayer.metrics = formattedPlayerMetrics

    addItemToGroup(formattedPlayerBreakdowns, formattedPlayer, key, 'id', key)
  }

  return {
    totals: {
      id: 'totals',
      player: null,
      metrics: formattedTotalMetrics
    },
    players: formattedPlayerBreakdowns
  }
}

export const formatDrillSessionDuration = (duration: number) => {
  duration = Math.floor(duration)
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration % 3600) / 60)
  const seconds = duration % 60

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0'
  )}:${String(seconds).padStart(2, '0')}`
}

export const getDefaultRegion = (
  coordinates: PitchCoordinates,
  sport: Sport
) => {
  if (!sport.props.pitch?.defaultDrillRegion) {
    return null
  }
  return {
    P1: coordinates[sport.props.pitch?.defaultDrillRegion?.P1],
    P2: coordinates[sport.props.pitch?.defaultDrillRegion?.P2],
    P3: coordinates[sport.props.pitch?.defaultDrillRegion?.P3],
    P4: coordinates[sport.props.pitch?.defaultDrillRegion?.P4],
    P5: coordinates[sport.props.pitch?.defaultDrillRegion?.P5],
    P6: coordinates[sport.props.pitch?.defaultDrillRegion?.P6],
    P7: coordinates[sport.props.pitch?.defaultDrillRegion?.P7],
    P8: coordinates[sport.props.pitch?.defaultDrillRegion?.P8]
  }
}
