import { defaultPitch } from './pitch'
import { canadianFootballEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FlightEventTypeGroup } from '../../events/flight/data_types'
import { FormattedEventData } from '../../events/types'
import { gameEventSubTypes } from '../../events/game/subType/data_types'
import { gameEventTypes } from '../../events/game/data_types'
import dataTypes from './data_types_config.json'
import {
  getMetricTypeGroup,
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../../metrics/data_types'

export const canadianFootballProps = {
  eventTypes: canadianFootballEventTypes,
  playerSummaryMetricTypes: getMetricTypeGroup(
    getMetricTypesConfigFromJson(
      dataTypes.playerSummary.metrics,
      metricTypesConfig
    )
  ),
  pitch: {
    default: defaultPitch,
    defaultDoublePitch: null,
    pitchPointCount: 34,
    setupTypes: pitchSetupTypes,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 13,
    tenYardMarkings: 11,
    enableBench: true,
    defaultDrillRegion: {
      P1: 'P26',
      P2: 'P14',
      P3: 'P13',
      P4: 'P1',
      P5: null,
      P6: null,
      P7: null,
      P8: null
    } as const,
    drillsGoalsOrPosts: {
      enabled: false,
      dimensions: {
        large: {
          width: 7.32,
          height: 2.44,
          depth: 2.44
        },
        small: {
          width: 1.914,
          height: 1.914,
          depth: 1.61
        }
      }
    },
    drillSnapLines: [
      'P1',
      'P2',
      'P3',
      'P4',
      'P5',
      'P6',
      'P7',
      'P8',
      'P9',
      'P10',
      'P11',
      'P12',
      'P13',
      'P14',
      'P15'
    ],
    autosetupTagPositions: ['P8', 'P15', 'P17'],
    pitchCornerKeys: {
      topLeft: 'P30',
      topRight: 'P16',
      bottomRight: 'P15',
      bottomLeft: 'P1'
    },
    leftAutosetupRegion: {
      topLeft: 'P30',
      topRight: 'P23',
      bottomRight: 'P8',
      bottomLeft: 'P1'
    },
    rightAutosetupRegion: {
      topLeft: 'P23',
      topRight: 'P16',
      bottomRight: 'P15',
      bottomLeft: 'P8'
    },
    leftTeamRegion: {
      topLeft: 'P30',
      topRight: 'P29',
      bottomRight: 'P2',
      bottomLeft: 'P1'
    },
    rightTeamRegion: {
      topLeft: 'P17',
      topRight: 'P16',
      bottomRight: 'P15',
      bottomLeft: 'P14'
    }
  },
  features: {
    enablePromoteAllDevicesOnPitch: true,
    targetEvents: ['pass'],
    positionNumbers: false,
    maxPlayers: 46,
    defaultUnitSystem: 'Canadian',
    keyboardShortcuts: {
      shortcuts: {
        c: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              (formattedEvent.type.selected?.value ===
                flightTypes.items.pass?.value ||
                formattedEvent.type.selected?.value ===
                  gameEventTypes.items.manualPass?.value ||
                formattedEvent.type.selected?.value ===
                  gameEventTypes.items.touchDown?.value)
            )
          },
          outcome: outcomeTypes.items.complete?.value,
          subType: gameEventSubTypes.items.manualPassComplete?.value
        },
        x: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              (formattedEvent.type.selected?.value ===
                flightTypes.items.pass?.value ||
                formattedEvent.type.selected?.value ===
                  gameEventTypes.items.manualPass?.value)
            )
          },
          outcome: outcomeTypes.items.incomplete?.value,
          subType: gameEventSubTypes.items.manualPassIncomplete?.value
        },
        // d: {
        //   condition: (
        //     formattedEvent: FormattedEventData,
        //     flightTypes: FlightEventTypeGroup
        //   ) => {
        //     return (
        //       formattedEvent &&
        //       formattedEvent.type.selected?.value ===
        //         flightTypes.items.pass?.value
        //     )
        //   },
        //   outcome: outcomeTypes.items.touchdown?.value
        // },
        i: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              (formattedEvent.type.selected?.value ===
                flightTypes.items.pass?.value ||
                formattedEvent.type.selected?.value ===
                  gameEventTypes.items.manualPass?.value)
            )
          },
          outcome: outcomeTypes.items.interception?.value,
          subType: gameEventSubTypes.items.manualPassIntercepted?.value
        }
      },
      codingShortcuts: {
        p: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.manualPass
        },
        s: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.handOff
        },
        f: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.tackle
        },
        n: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.penalty
        },
        w: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.huddleBreak
        },
        e: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.lineSet
        },
        d: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.qbRush
        },
        g: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.qbSack
        }
      }
    },
    recentEventTableMetrics: [
      'inPitchDistance',
      'inPitchHangTime',
      'speed',
      'territorialGain'
    ],
    broadcastIntegrationAvailable: true,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: true,
    bibs: {}
  } as const
}
