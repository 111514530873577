import {
  Anchors,
  PitchArcs,
  PitchCoordinates
} from '../../metrics_server/pitches/types'
import { SportTypeValues } from '../../metrics_server/sports/data_types'
import { Team } from '../../metrics_server/teams/types'
import { CSSProperties } from 'react'
import {
  FormattedSession,
  RawPlayerSessionData
} from '../../metrics_server/sessions/types'
import { Field } from '../../utils/strack/types'

// New enum for canvas IDs
export enum CanvasId {
  DRILLS = 'drills',
  CHALLENGE = 'challenge',
  VALIDATION = 'validation',
  CODING = 'coding',
  OVERVIEW = 'overview',
  SPORTSCASTER = 'sportscaster',
  RECENTEVENTS = 'recentEvents'
}

export interface StrackProps {
  session?: FormattedSession
  pitch: StrackPitch
  anchorConfig: Anchors
}

export interface StrackOptions {
  eventLabels?: boolean
  session?: FormattedSession
  teams?: boolean
  anchors?: boolean
  tagInfo?: boolean
  centerLogo?: boolean
  targets?: boolean
  babylonActive?: boolean
  sessionId?: string
  canvasId?: CanvasId | string
  cover?: string
  show2DOnly?: boolean
  tracking?: boolean
  customTrackingSource?: boolean
  initialView?: CanvasViewTypes
  anchorSetup?: boolean
  validation?: boolean
  disableRotate?: boolean
  disableFlip?: boolean
  diags?: boolean
  playersSessions?: RawPlayerSessionData[]
  pitch?: StrackPitch
  anchorConfig?: Anchors
  team?: Team
  teamB?: Team
  players?: any
  centerImgSrc?: string
  events?
  keyboardInputs?
  minPadding2D?: number
  broadcastIntegrationEnabled?: boolean
  touchToRefDistance?: boolean
  eventsClickable?: boolean
  enableBench?: boolean
  operatorControls?: boolean
  drillRegions?: boolean
  doublePitchSetup?: boolean
  demonstrationPitch?: boolean
}

export interface StrackPitch {
  type: SportTypeValues
  coordinates: PitchCoordinates
  arcs?: PitchArcs
}

export type CanvasStyle = CSSProperties

export interface Strack {
  options: StrackOptions
  canvasStyle: CanvasStyle
  field: Field
}

export type InitiateStrack = (
  options: StrackOptions,
  canvasStyle: CanvasStyle,
  cb
) => void

export interface Strack {
  [key: string]: any
}

export interface Canvas {
  strack: Strack
  options: StrackOptions
  canvasStyle: CanvasStyle
  cb: (any) => any
}

export interface StrackControllerProps {
  active?: boolean
  strack?: Strack
  strackReady?: boolean
  setResetStrack?: (value: boolean) => void
  canvasStyle: CanvasStyle
  canvasView?: CanvasViewTypes
  toggleCanvasView?: (view: string) => void
  toggleCanvasVisibility?: (view: string) => void
  show2DOnly?: boolean
  updateCanvasWidth?: (canvasId: CanvasId, width: string) => void
  setCanvasStyle?: (canvasId: CanvasId, height: string) => void
  hideCanvas?: boolean
  canvasId?: CanvasId | string
}

export interface Canvases {
  [canvasId: string]: Canvas
}

export interface CanvasViews {
  [canvasId: string]: CanvasViewTypes
}

export type CanvasViewTypes = '2D' | '3D'
