import React, { useState } from 'react'
import styles from './styles.module.scss'
import { FullPitchTagListSetupForm } from './full_pitch_tag_setup_form'
import TextInput from '../Inputs/TextInput/TextInput'
import { Button } from '../../Button/Button'
import Select from '../Inputs/Select/Select'
import { Toggle } from '../../Toggle/Toggle'
import TotalStationSetupForm from './total_station'
import {
  useAnchorPitchSetup,
  usePitchSelector,
  usePitches
} from '../../../metrics_server/pitches/hooks'
import { useFormattedSession } from '../../../metrics_server/sessions/hooks'
import {
  updateMaster,
  updateAnchorBump,
  updatePitchSide,
  updatePitchSetupType,
  setDoublePitchSetup,
  setDemonstrationPitch
} from '../../../metrics_server/pitches/actions'
import { useAppDispatch } from '../../../store/hooks'
import { JSONUpload } from './json_upload'
import Checkbox from '../Inputs/Checkbox/Checkbox'
import { useUser } from '../../../metrics_server/user/hooks'

const PitchSetupForm = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const pitches = usePitches()
  const user = useUser()

  // Remove admin check when roles are introduced
  const isAdmin = true

  const formattedSession = useFormattedSession('setup')
  const { sport, anchors } = formattedSession

  const { handleAnchorPitchSetup } = useAnchorPitchSetup()

  const pitchSetupTypes = sport.props.pitch.setupTypes

  const { selectedPitchId, setSelectedPitchId, pitchOptions } =
    usePitchSelector()

  const setSelectedPitchFn = (_, value: number) => {
    setSelectedPitchId(value)
  }

  const handlePitchQuantityChange = (_, value) => {
    if (value === 'double') {
      dispatch(setDoublePitchSetup(true))
    } else {
      dispatch(setDoublePitchSetup(false))
    }
  }

  const autosetupButtonDisabled = pitches.doublePitchSetup
    ? !(
        pitches.setupTagList[0] &&
        pitches.setupTagList[1] &&
        pitches.setupTagList[2] &&
        pitches.secondPitchSetupTagList[1]
      )
    : !(
        pitches.setupTagList[0] &&
        pitches.setupTagList[1] &&
        pitches.setupTagList[2]
      )

  return (
    <div className={styles.formContainer}>
      <div className={styles.header}>
        <h5>Pitch Setup</h5>
      </div>
      {sport.props.pitch.defaultDoublePitch && (
        <div className={styles.selectContainer}>
          <Select
            className='small-select'
            options={[
              { name: 'Single Pitch', value: 'single' },
              { name: 'Double Pitch', value: 'double' }
            ]}
            input={{
              name: 'selectedPitch',
              value: pitches.doublePitchSetup ? 'double' : 'single'
            }}
            updateValue={handlePitchQuantityChange}
          />
        </div>
      )}

      {user.data.appsPermissions.includes(2) && (
        <div className={styles.demoCheckbox}>
          <Checkbox
            label={`Use Laboratory Pitch`}
            size='small'
            onClicked={(value) => dispatch(setDemonstrationPitch(value))}
            checked={pitches.demonstrationPitch}
          />
        </div>
      )}

      {isAdmin && (
        <div className={styles.selectContainer}>
          <Toggle
            input={{
              value: pitches.setupType,
              onChange: (value) => dispatch(updatePitchSetupType(value))
            }}
            options={pitchSetupTypes.options}
          />
        </div>
      )}
      {pitches.setupType === pitchSetupTypes.items.auto.value && (
        <React.Fragment>
          <div className={styles.selectContainer}>
            <Toggle
              input={{
                value: pitches.side,
                onChange: (value) => dispatch(updatePitchSide(value))
              }}
              options={[
                { name: 'Left', value: -1 },
                { name: 'Right', value: 1 },
                { name: 'Full', value: 0 }
              ]}
            />
          </div>
          {isAdmin && pitches.side !== 0 && (
            <div className={styles.selectContainer}>
              <h5>Set master:</h5>
              <div className={styles.masterSelect}>
                <div style={{ fontSize: '13px' }}>Master:</div>
                <div className='smallSelectContainer'>
                  <Select
                    className='small-select'
                    options={anchors.options}
                    input={{ name: 'masterSelect', value: pitches.master }}
                    updateValue={(value) => {
                      dispatch(updateMaster(value))
                    }}
                  />
                </div>
                {pitches.side !== 0 && (
                  <React.Fragment>
                    <div style={{ fontSize: '13px' }}>Bump:</div>
                    <div className='smallSelectContainer'>
                      <TextInput
                        small={true}
                        input={{
                          onChange: (e) =>
                            dispatch(updateAnchorBump(e.target.value)),
                          name: 'anchorBump',
                          value: pitches.anchorBump
                        }}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
          {pitches.side === 0 && <FullPitchTagListSetupForm />}
          <div className={styles.buttonsContainer}>
            <div>
              <Button
                handleClick={() => {
                  handleAnchorPitchSetup(pitchSetupTypes.items.auto.value)
                }}
                className='btn--primary'
                disabled={autosetupButtonDisabled}
              >
                Setup Pitch
              </Button>
            </div>
          </div>
        </React.Fragment>
      )}
      {pitches.setupType === pitchSetupTypes.items.pitch.value && (
        <React.Fragment>
          <div className={styles.selectContainer}>
            <h5>Pitch Setup:</h5>
            <div className={styles.masterSelect}>
              <div style={{ fontSize: '13px' }}>Type:</div>
              <div className='smallSelectContainer'>
                <Select
                  className='small-select'
                  options={pitchOptions}
                  input={{ name: 'selectedPitch', value: selectedPitchId }}
                  updateValue={setSelectedPitchFn}
                />
              </div>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <div>
              <Button
                handleClick={() =>
                  handleAnchorPitchSetup(pitchSetupTypes.items.pitch.value, {
                    selectedPitchId
                  })
                }
                className='btn--primary'
              >
                Set Pitch
              </Button>
            </div>
          </div>
        </React.Fragment>
      )}
      {pitches.setupType === pitchSetupTypes.items.totalStation.value && (
        <TotalStationSetupForm />
      )}
      {pitches.setupType === pitchSetupTypes.items.uploadJSON.value && (
        <JSONUpload />
      )}
    </div>
  )
}

export default PitchSetupForm
