import { defaultPitch } from './pitch'
import { rugbyUnionEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FormattedEventData } from '../../events/types'
import { FlightEventTypeGroup } from '../../events/flight/data_types'
import { getMetricTypeGroup } from '../../metrics/data_types'
import {
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../../metrics/data_types'
import dataTypes from './data_types_config.json'

export const rugbyUnionProps = {
  eventTypes: rugbyUnionEventTypes,
  playerSummaryMetricTypes: getMetricTypeGroup(
    getMetricTypesConfigFromJson(
      dataTypes.playerSummary.metrics,
      metricTypesConfig
    )
  ),
  pitch: {
    default: defaultPitch,
    defaultDoublePitch: null,
    pitchPointCount: 34,
    setupTypes: pitchSetupTypes,
    minPadding2D: 80,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 11,
    crossfieldLines: [
      { name: 'Try line', lower: 'P2', upper: 'P25' },
      { name: '5 meter line', lower: 'P3', upper: 'P24' },
      { name: '22 meter line', lower: 'P4', upper: 'P23' },
      { name: '10 meter line', lower: 'P5', upper: 'P22' },
      { name: 'Halfway line', lower: 'P6', upper: 'P21' },
      { name: '10 meter line', lower: 'P7', upper: 'P20' },
      { name: '22 meter line', lower: 'P8', upper: 'P19' },
      { name: '5 meter line', lower: 'P9', upper: 'P18' },
      { name: 'Try line', lower: 'P10', upper: 'P17' }
    ],
    enableBench: true,
    enableRotate: false,
    defaultDrillRegion: {
      P1: 'P25',
      P2: 'P17',
      P3: 'P10',
      P4: 'P2',
      P5: 'P32',
      P6: 'P33',
      P7: 'P31',
      P8: 'P34'
    } as const,
    drillsGoalsOrPosts: {
      enabled: false,
      dimensions: {
        large: {
          width: 7.32,
          height: 2.44,
          depth: 2.44
        },
        small: {
          width: 1.914,
          height: 1.914,
          depth: 1.61
        }
      }
    },
    drillSnapLines: [
      'P1',
      'P2',
      'P3',
      'P4',
      'P5',
      'P6',
      'P7',
      'P8',
      'P9',
      'P10',
      'P11'
    ],
    autosetupTagPositions: ['P6', 'P10', 'P19'],
    pitchCornerKeys: {
      topLeft: 'P26',
      topRight: 'P16',
      bottomRight: 'P11',
      bottomLeft: 'P1'
    },
    leftAutosetupRegion: {
      topLeft: 'P26',
      topRight: 'P20',
      bottomRight: 'P7',
      bottomLeft: 'P1'
    },
    rightAutosetupRegion: {
      topLeft: 'P22',
      topRight: 'P16',
      bottomRight: 'P11',
      bottomLeft: 'P5'
    },
    leftTeamRegion: {
      topLeft: 'P26',
      topRight: 'P25',
      bottomRight: 'P2',
      bottomLeft: 'P1'
    },
    rightTeamRegion: {
      topLeft: 'P17',
      topRight: 'P16',
      bottomRight: 'P11',
      bottomLeft: 'P10'
    }
  },
  features: {
    enablePromoteAllDevices: true,
    targetEvents: ['kick'],
    positionNumbers: true,
    maxPlayers: 23,
    defaultUnitSystem: 'British',
    keyboardShortcuts: {
      screenName: 'Validation - Kick Detail Card',
      shortcuts: {
        u: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.kick?.value
            )
          },
          outcome: outcomeTypes.items.uncontested.value
        },
        c: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.kick?.value
            )
          },
          outcome: outcomeTypes.items.contestedLost.value
        },
        a: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.kick?.value
            )
          },
          outcome: outcomeTypes.items.retained.value
        },
        l: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.lineout?.value
            )
          },
          outcome: outcomeTypes.items.lost.value
        },
        w: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.lineout?.value
            )
          },
          outcome: outcomeTypes.items.won.value
        }
      }
    },
    recentEventTableMetrics: [
      'inPitchDistance',
      'inPitchHangTime',
      'speed',
      'territorialGain'
    ],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    homeSideCheck: false,
    scoreboard: true,
    bibs: {}
  } as const
}
