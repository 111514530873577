import {
  BroadcastingState,
  SET_FLIGHT_PUBLISHED,
  TOGGLE_AUTO_BROADCASTING,
  UPDATE_BROADCAST_STATE
} from './types'

const initialState: BroadcastingState = {
  enabled: null,
  publishedFlights: [],
  msState: null,
  autoBroadcastEnabled: false,
  state: {
    session: null,
    sessionControl: {
      sides: null,
      possession: null
    },
    broadcastingEnabled: false,
    host: null
  }
}

export function broadcastingReducer(
  state = initialState,
  action
): BroadcastingState {
  if (action.type === TOGGLE_AUTO_BROADCASTING) {
    return {
      ...state,
      autoBroadcastEnabled: action.payload
    }
  } else if (action.type === UPDATE_BROADCAST_STATE) {
    return {
      ...state,
      state: action.payload,
      autoBroadcastEnabled: action.payload.broadcastingEnabled
    }
  } else if (action.type === SET_FLIGHT_PUBLISHED) {
    return {
      ...state,
      publishedFlights: [...state.publishedFlights, action.payload]
    }
  } else {
    return state
  }
}
