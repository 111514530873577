import React, { useMemo } from 'react'
import { StrackCanvas } from '../../../Strack/StrackContainer/StrackCanvas'
import StrackContainer from '../../../Strack/StrackContainer/StrackContainer'
import { StrackStandard } from '../../../Strack/StrackContainer/StrackStandard'
import { MainProps, Tab } from '../Main.types'
import { TabContentWrapper } from './TabContentWrapper'
import { ViewStrackProps } from '../../Views.types'

interface ContentProps {
  view: MainProps
  tabsArray: Tab[]
  activeTabKey: string
  selectedTabKey: string
  switchTab: (tabName: string) => void
  updateActiveTab: (tabName: string) => void
  animationComplete: boolean
}

export const useDefaultStrackProps = (): ViewStrackProps => {
  return useMemo(() => {
    return {
      enabled: false,
      pitch: null,
      anchorConfig: null,
      session: null,
      hiddenCanvases: [],
      doublePitchSetup: false
    }
  }, [])
}

export function Content(props: ContentProps) {
  const { tabsArray, view } = props

  const { activeTabKey, selectedTabKey, updateActiveTab, animationComplete } =
    props

  const { useStrackProps = useDefaultStrackProps } = view

  const strackProps = useStrackProps(view)

  return (
    <StrackContainer padding={0} hiddenCanvases={strackProps.hiddenCanvases}>
      {tabsArray && tabsArray.length > 0 ? (
        tabsArray.map((tab, index) => {
          if (strackProps.enabled && tab.strack) {
            return (
              <StrackCanvas
                {...props}
                key={index}
                active={activeTabKey === tab.key}
                selected={selectedTabKey === tab.key}
                canvasId={tab.strack.options.canvasId}
                strackOptions={{
                  pitch: strackProps.pitch,
                  doublePitchSetup: strackProps.doublePitchSetup,
                  demonstrationPitch: strackProps.demonstrationPitch,
                  anchorConfig: strackProps.anchorConfig,
                  sessionId: strackProps.session?.id,
                  session: strackProps.session,
                  ...tab.strack.options
                }}
                canvasStyle={tab.strack.canvasStyle}
              >
                <TabContentWrapper
                  active={activeTabKey === tab.key}
                  selectedTabKey={selectedTabKey}
                  updateActiveTab={updateActiveTab}
                >
                  <tab.Content
                    {...props}
                    active={activeTabKey === tab.key}
                    tab={tab}
                  />
                </TabContentWrapper>
              </StrackCanvas>
            )
          } else {
            return (
              <StrackStandard
                active={activeTabKey === tab.key}
                selected={selectedTabKey === tab.key}
                key={index}
              >
                <TabContentWrapper
                  active={activeTabKey === tab.key}
                  selectedTabKey={selectedTabKey}
                  updateActiveTab={updateActiveTab}
                >
                  <tab.Content
                    {...props}
                    active={activeTabKey === tab.key}
                    tab={tab}
                  />
                </TabContentWrapper>
              </StrackStandard>
            )
          }
        })
      ) : (
        <view.Content {...props} animationComplete={animationComplete} />
      )}
    </StrackContainer>
  )
}
