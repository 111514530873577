import React, { useEffect, useState } from 'react'
import { CanvasStyle, CanvasViewTypes, Strack } from '../Strack.types'
import { useEvents } from '../../../metrics_server/events/hooks'
import { StrackOverlay } from '../StrackOverlays/StrackOverlay'
import { StrackEventLabel } from '../../StrackEventLabel/StrackEventLabel'
import { useAppDispatch } from '../../../store/hooks'
import { setSelectedFlight } from '../../../metrics_server/events/actions'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'
import { useUnitsSystem } from '../../../metrics_server/units/hooks'

export interface StrackEventsProps {
  canvasStyle: CanvasStyle
  strackReady: boolean
  active: boolean
  events: any
  strack: Strack
  maxNumberOfEvents?: number
  canvasView: CanvasViewTypes
}

export const StrackEvents = ({
  canvasStyle,
  strackReady,
  canvasView,
  active,
  events,
  strack,
  maxNumberOfEvents = 40
}: StrackEventsProps) => {
  const dispatch = useAppDispatch()

  const { sport } = useSelectedFormattedSession()
  const { selectedEventId } = useEvents()
  const unitSystem = useUnitsSystem(sport)

  const [showEventLabel, setShowEventLabel] = useState(false)

  // Initiate events canvas //
  useEffect(() => {
    if (strackReady && strack) {
      strack?.events.eventHandleCanvas.addEventListener('click', (e) => {
        // Event listener //
        if (strack.eventsClickable) {
          strack.eventsCanvasClickHandle(
            e,
            (eventId) => {
              if (eventId) {
                strack.checkForTagOnClick(e, (tagId) => {
                  if (!tagId) {
                    dispatch(setSelectedFlight(eventId))
                    if (strack.eventLabels) setShowEventLabel(true)
                  }
                })
              } else {
                dispatch(setSelectedFlight(null))
              }
            },
            true
          )
        }
      })
    }
  }, [strackReady])

  // Hide event label when switching to another tab //
  useEffect(() => {
    if (!active) {
      setShowEventLabel(false)
    }
  }, [active])

  // Show event label when selected event updates - remove if no event selected //
  useEffect(() => {
    if (selectedEventId) {
      if (canvasView === '2D') {
        setShowEventLabel(true)
      }
    } else {
      setShowEventLabel(false)
    }
  }, [selectedEventId])

  // Set strack highlighted flight when selected event updates //
  useEffect(() => {
    if (selectedEventId) {
      if (strackReady && strack) {
        strack.highlightFlight(selectedEventId)
      }
    } else {
      if (strackReady && strack) {
        strack.unhighlightFlight()
      }
    }
  }, [selectedEventId])

  // Hide event label when switching to 3D //
  useEffect(() => {
    setShowEventLabel(false)
  }, [canvasView])

  // Update events on canvas
  useEffect(() => {
    if (strackReady && strack) {
      const filteredEvents = events.filter((x, i) => {
        if (strack.events.highlightedId === x.id) {
          return true
        } else if (i <= maxNumberOfEvents) {
          return true
        }
        return false
      })
      strack.unitSystem = unitSystem
      strack.events.events = filteredEvents
      strack.plotEventsOnCanvas(null, true)
      strack.renderKicks(null, null, true)
    }
  }, [events, maxNumberOfEvents, unitSystem, selectedEventId, strackReady])

  return (
    <StrackOverlay canvasStyle={canvasStyle} strackReady={strackReady}>
      {strackReady && strack?.eventLabels && (
        <StrackEventLabel strack={strack} show={showEventLabel} />
      )}
    </StrackOverlay>
  )
}
