import { useMemo } from 'react'
import { useAppSelector } from '../../store/hooks'
import { useSelectedFormattedSession } from '../sessions/hooks'
import { formatDrill, formatPlayerBreakdown } from './functions'
import { useUnitsSystem } from '../units/hooks'
import { FormattedPlayerBreakdowns, ProcessedDrill } from './types'
import { getEmptyGroup } from '../sessions/functions'
import { addItemToGroup } from '../types'

// TODO add return type to useDrills
export const useDrills = () => {
  return useAppSelector((state) => state.drills)
}

export const useDrillsGroup = () => {
  const { processedData } = useDrills()

  return useMemo(() => {
    const drillGroup = getEmptyGroup<ProcessedDrill>()

    Object.values(processedData).forEach((drill) => {
      addItemToGroup(drillGroup, drill, drill.id, 'id', drill.name)
    })

    return drillGroup
  }, [processedData])
}

export const useSelectedFormattedDrill = () => {
  const { selectedId } = useDrills()
  return useFormattedDrill(selectedId)
}

export const useActiveFormattedDrill = () => {
  const { processedData } = useDrills()
  const activeDrill = Object.values(processedData).find(
    (drill) => drill.isActive
  )
  return useFormattedDrill(activeDrill?.id)
}

export const useFormattedDrills = () => {
  const formattedSession = useSelectedFormattedSession()
  const drillGroup = useDrillsGroup()

  return useMemo(() => {
    return drillGroup.list.map((drill) => {
      return formatDrill(drill, formattedSession)
    })
  }, [drillGroup, formattedSession])
}

export const useFormattedDrill = (drillId: string) => {
  const formattedSession = useSelectedFormattedSession()
  const drillGroup = useDrillsGroup()
  const drill = drillGroup.map[drillId]

  const selectedDrill = useMemo(() => {
    return formatDrill(drill, formattedSession)
  }, [drill, formattedSession])

  return selectedDrill
}

export const usePlayerBreakdown = () => {
  const { selectedId, playerBreakdowns } = useDrills()

  const formattedSession = useSelectedFormattedSession()

  const unitSystem = useUnitsSystem(formattedSession.sport)

  const formattedPlayerBreakdowns = useMemo<FormattedPlayerBreakdowns>(() => {
    if (playerBreakdowns[selectedId]) {
      return formatPlayerBreakdown(
        playerBreakdowns[selectedId],
        formattedSession,
        unitSystem
      )
    }
    return {
      players: getEmptyGroup(),
      totals: null
    }
  }, [playerBreakdowns, formattedSession, selectedId, unitSystem])

  return formattedPlayerBreakdowns
}
