import { defaultPitch } from './pitch'
import { australianRulesEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FormattedEventData } from '../../events/types'
import { FlightEventTypeGroup } from '../../events/flight/data_types'
import dataTypes from './data_types_config.json'
import {
  getMetricTypeGroup,
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../../metrics/data_types'

export const australianRulesProps = {
  eventTypes: australianRulesEventTypes,
  playerSummaryMetricTypes: getMetricTypeGroup(
    getMetricTypesConfigFromJson(
      dataTypes.playerSummary.metrics,
      metricTypesConfig
    )
  ),
  pitch: {
    default: defaultPitch,
    defaultDoublePitch: null,
    pitchPointCount: 37,
    setupTypes: pitchSetupTypes,
    minPadding2D: 50,
    minPadding3D: 50,
    cameraYScale: 0,
    textureCanvasScale: 8,
    enableBench: false,
    defaultDrillRegion: {
      P1: 'P26',
      P2: 'P14',
      P3: 'P13',
      P4: 'P1',
      P5: null,
      P6: null,
      P7: null,
      P8: null
    } as const,
    drillsGoalsOrPosts: {
      enabled: false,
      dimensions: {
        large: {
          width: 7.32,
          height: 2.44,
          depth: 2.44
        },
        small: {
          width: 1.914,
          height: 1.914,
          depth: 1.61
        }
      }
    },
    drillSnapLines: [
      'P1',
      'P2',
      'P3',
      'P4',
      'P5',
      'P6',
      'P7',
      'P8',
      'P9',
      'P10',
      'P11',
      'P12',
      'P13'
    ],
    autosetupTagPositions: ['leftOrigin', 'rightOrigin', 'P23'],
    leftTeamRegion: {
      topLeft: 'P16',
      topRight: 'P16',
      bottomRight: 'P15',
      bottomLeft: 'P15'
    },
    rightTeamRegion: {
      topLeft: 'P30',
      topRight: 'P30',
      bottomRight: 'P31',
      bottomLeft: 'P31'
    }
  },
  features: {
    targetEvents: ['kick'],
    positionNumbers: false,
    maxPlayers: 22,
    defaultUnitSystem: 'Metric',
    keyboardShortcuts: {},
    recentEventTableMetrics: [
      'inPitchDistance',
      'inPitchHangTime',
      'speed',
      'territorialGain'
    ],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: false,
    bibs: {}
  } as const
}
