import React, { CSSProperties, useEffect, useRef } from 'react'
import { drawTryZones } from '../../../../utils/strack/3D/build'
import { sportableColors } from '../../../../const'
import styles from './Teams.module.scss'
import { AflTeams } from './AFLTeams'
import {
  useFormattedSession,
  useSessionTeamSides
} from '../../../../metrics_server/sessions/hooks'
import { CanvasViewTypes, Strack } from '../../Strack.types'
import { isAFL, isSoccer } from '../../../../metrics_server/sports/functions'
import { extractSecondPitchDimensions } from '../../../../utils/strack/functions'
import { sportTypes } from '../../../../metrics_server/sports/data_types'

export interface StrackTeamsProps {
  strack: Strack
  canvasView: CanvasViewTypes
}

export function StrackTeams(props: StrackTeamsProps) {
  const { strack, canvasView } = props

  const {
    session,
    field,
    getCanvasCoordinate,
    scale,
    dimensions,
    canvas2DPixelScale
  } = strack

  const { id, sport, isTrainingMode } = useFormattedSession(session.id)
  const { sessionTeamSides, highlightedSide } = useSessionTeamSides(id)

  const secondPitchDimensions = extractSecondPitchDimensions(sport, dimensions)

  const ref = useRef(null)

  useEffect(() => {
    drawTryZones(strack, sessionTeamSides, highlightedSide)
  }, [highlightedSide, sessionTeamSides])

  if (
    !sessionTeamSides ||
    canvasView !== '2D' ||
    secondPitchDimensions ||
    isTrainingMode ||
    strack.pitchType === sportTypes.items.demonstration.value
  ) {
    return null
  }

  // If training mode don't draw anything
  if (isTrainingMode) return null

  const { leftTeamRegion, rightTeamRegion } = sport.props.pitch

  let leftTeamRegionCoordinates =
    strack.getCanvasRectStylesFromPitchCoordinates(
      dimensions[leftTeamRegion.topLeft],
      dimensions[leftTeamRegion.topRight],
      dimensions[leftTeamRegion.bottomRight],
      dimensions[leftTeamRegion.bottomLeft],
      strack.getCanvasCoordinate,
      strack.scale
    )

  let rightTeamRegionCoordinates =
    strack.getCanvasRectStylesFromPitchCoordinates(
      dimensions[rightTeamRegion.topLeft],
      dimensions[rightTeamRegion.topRight],
      dimensions[rightTeamRegion.bottomRight],
      dimensions[rightTeamRegion.bottomLeft],
      strack.getCanvasCoordinate,
      strack.scale
    )

  if (
    leftTeamRegionCoordinates.width === 0 ||
    leftTeamRegionCoordinates.height === 0
  ) {
    if (strack.pitchFlipped && strack.pitchRotated) {
      leftTeamRegionCoordinates = {
        ...leftTeamRegionCoordinates,
        y: leftTeamRegionCoordinates.y + 30
      }
    } else if (strack.pitchRotated) {
      leftTeamRegionCoordinates = {
        ...leftTeamRegionCoordinates,
        y: leftTeamRegionCoordinates.y - 30
      }
    } else if (strack.pitchFlipped) {
      leftTeamRegionCoordinates = {
        ...leftTeamRegionCoordinates,
        x: leftTeamRegionCoordinates.x + 30
      }
    } else {
      leftTeamRegionCoordinates = {
        ...leftTeamRegionCoordinates,
        x: leftTeamRegionCoordinates.x - 30
      }
    }
  }

  if (
    rightTeamRegionCoordinates.width === 0 ||
    rightTeamRegionCoordinates.height === 0
  ) {
    if (strack.pitchFlipped && strack.pitchRotated) {
      rightTeamRegionCoordinates = {
        ...rightTeamRegionCoordinates,
        y: rightTeamRegionCoordinates.y - 30
      }
    } else if (strack.pitchRotated) {
      rightTeamRegionCoordinates = {
        ...rightTeamRegionCoordinates,
        y: rightTeamRegionCoordinates.y + 30
      }
    } else if (strack.pitchFlipped) {
      rightTeamRegionCoordinates = {
        ...rightTeamRegionCoordinates,
        x: rightTeamRegionCoordinates.x - 30
      }
    } else {
      rightTeamRegionCoordinates = {
        ...rightTeamRegionCoordinates,
        x: rightTeamRegionCoordinates.x + 30
      }
    }
  }

  const leftStyles = {
    position: 'absolute',
    top: leftTeamRegionCoordinates.y,
    left: leftTeamRegionCoordinates.x,
    height: `${leftTeamRegionCoordinates.height}px`,
    width: `${leftTeamRegionCoordinates.width}px`
  } as CSSProperties

  const rightStyles = {
    position: 'absolute',
    top: rightTeamRegionCoordinates.y,
    left: rightTeamRegionCoordinates.x,
    height: `${rightTeamRegionCoordinates.height}px`,
    width: `${rightTeamRegionCoordinates.width}px`
  } as CSSProperties

  const getOverlayStyle = (teams, index, highlightedSide) => {
    const team = teams[index]
    let overlayStyle = {
      backgroundColor:
        highlightedSide === index ? sportableColors.colors.blue : 'black',
      opacity: highlightedSide === index ? 0.6 : 0.3
    }

    if (highlightedSide === null && team) {
      overlayStyle = {
        backgroundColor: team.color,
        opacity: 0.3
      }
    }

    return overlayStyle
  }

  const highlightTeamName = (teams, index, highlightedSide) => {
    const team = teams[index]

    const highlightText =
      leftTeamRegionCoordinates.width === 0 ||
      leftTeamRegionCoordinates.height === 0

    let overlayStyle = {
      color:
        highlightText && highlightedSide === index
          ? sportableColors.colors.blue
          : 'white',
      opacity: highlightedSide === index ? 1 : 0.6
    }

    if (highlightedSide === null && team) {
      overlayStyle = {
        color: team.color,
        opacity: 0.6
      }
    }

    return {
      ...overlayStyle
    }
  }

  const placeHolderLogo = (color) => (
    <div className={styles.teamImg}>
      <svg viewBox='0 0 46 50' height={'25px'} version='1.1'>
        <defs />
        <g
          id='Page-1'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Placeholder/team'
            transform='translate(-2.000000, 0.000000)'
            strokeWidth='2'
          >
            <g id='Group' transform='translate(3.000000, 2.000000)'>
              <path
                stroke={color || 'black'}
                d='M44,11.5 C44,28.3666667 36.6666667,39.8666667 22,46 C7.33333333,39.8666667 1.05167746e-15,28.3666667 0,11.5 L22,0 L44,11.5 Z'
                id='Path-3'
                fill='#FFFFFF'
              />
              <path
                stroke={color || 'black'}
                d='M22,42.0628377 C27.6726224,39.5180015 32.2147397,35.6056758 35.6263519,30.3258606'
                id='Path-16'
              />
              <path
                stroke={color || 'black'}
                d='M5.50059699,22.6050326 C4.82812972,20.4344044 4.32793073,17.8993935 4,15 L22,5.77165568 L25.7541204,7.80200704'
                id='Path-15'
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )

  return (
    <div ref={ref} className={styles.teamOverlay}>
      {strack && (
        <div className={styles.teamNames}>
          <div className={styles.leftTeamContainer} style={leftStyles}>
            <div
              className={styles.colorOverlay}
              style={getOverlayStyle(sessionTeamSides, 0, highlightedSide)}
            />
            <div
              className={styles.teamName}
              style={{
                ...highlightTeamName(sessionTeamSides, 0, highlightedSide),
                transform: !strack.pitchRotated
                  ? 'translate(-50%, -50%) rotate(-90deg)'
                  : 'translate(-50%, -50%)',
                fontSize: `${(field.width * scale) / 40}px`
              }}
            >
              {sessionTeamSides[0].logo ? (
                <img
                  id='team-logo'
                  height={'25px'}
                  className={styles.teamImg}
                  src={sessionTeamSides[0].logo}
                  alt=''
                />
              ) : (
                placeHolderLogo(sessionTeamSides[0].color)
              )}
              {sessionTeamSides[0].name}
            </div>
          </div>
          <div className={styles.rightTeamContainer} style={rightStyles}>
            <div
              className={styles.colorOverlay}
              style={getOverlayStyle(sessionTeamSides, 1, highlightedSide)}
            />
            <div
              className={styles.teamName}
              style={{
                ...highlightTeamName(sessionTeamSides, 1, highlightedSide),
                transform: !strack.pitchRotated
                  ? 'translate(-50%, -50%) rotate(-90deg)'
                  : 'translate(-50%, -50%)',
                fontSize: `${(field.width * scale) / 40}px`
              }}
            >
              {sessionTeamSides[1].logo ? (
                <img
                  id='team-logo'
                  height={'25px'}
                  className={styles.teamImg}
                  src={sessionTeamSides[1].logo}
                  alt=''
                />
              ) : (
                placeHolderLogo(sessionTeamSides[1].color)
              )}
              {sessionTeamSides[1].name}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
