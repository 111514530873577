import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { WebSocketController } from '../../utils/websockets.js'
import { permissionLevels } from '../../const'

// API types
import { HardwareState } from '../../metrics_server/hardware/types'
import { PitchesState, Anchor } from '../../metrics_server/pitches/types'

// UI types
import { RouterState } from '../../ui/router/types'

import * as pitchActions from '../../metrics_server/pitches/actions'
import * as diagsActions from '../../metrics_server/diags/actions'
import * as hardwareActions from '../../metrics_server/hardware/actions'
import { UserState } from '../../metrics_server/user/types.js'
import { VersionState } from '../../metrics_server/version/types.js'
import { getStrackPitchAndAnchorConfig } from '../../utils/strack/functions.js'

const actions = {
  ...pitchActions,
  ...diagsActions,
  ...hardwareActions
}

export interface DiagnosticsContainerProps {
  user: UserState
  hardware: HardwareState
  pitches: PitchesState
  router: RouterState
  version: VersionState

  // Actions
  getPitchConfig: any
  removePitchConfig: () => void

  toggleModal: (modal: any) => void

  updateMaster: (id: number) => void

  serviceAction: (tagIds: number[], type: string) => void
  enableRSSIAction: () => void
  disableRSSIAction: () => void

  setSelectedTag: (tagId: number) => void
}

export interface DiagnosticsContainerState {
  // Tabs
  // tabs: {}
  // initialTab: string

  selectedAnchors: Anchor[]
  options: []
  fetchingPitch: boolean
}

export const DiagnosticsContainer: any = (ChildComponent) => {
  class ComposedComponent extends Component<
    DiagnosticsContainerProps,
    DiagnosticsContainerState
  > {
    private loadingTimeouts = null

    constructor(props) {
      super(props)

      // let tabs = { ...tabConfig }

      // const initialTab = tabs.anchorHealth.name

      this.state = {
        // Tabs
        // tabs,
        // initialTab,

        // Anchors state
        selectedAnchors: [],
        options: [],
        fetchingPitch: true
      }

      this.loadingTimeouts = []
    }

    componentDidMount() {
      this.props.getPitchConfig()
    }

    componentDidUpdate(prevProps, prevState) {
      const { pitches } = this.props
      if (prevProps.pitches.inUse !== pitches.inUse) {
        this.setState({ fetchingPitch: false })
        const { anchors } = pitches.inUse
        this.setNewAnchors(anchors)
      }
    }

    componentWillUnmount() {
      WebSocketController.disconnectWebSocket('diagnostics', 'debugger')
      WebSocketController.disconnectWebSocket('raw', 'anchorHealth')

      this.loadingTimeouts.forEach((id) => {
        clearTimeout(id)
      })
    }

    /*========== Hardware Actions =========*/

    setNewAnchors = (anchors) => {
      this.setState({
        selectedAnchors: _.values(anchors)
      })
    }

    /*========== Tag and anchor functions ===========*/

    toggleAnchor = (anchorId) => {
      const { inUse } = this.props.pitches
      if (!anchorId) {
        this.setState({ selectedAnchors: [] })
      } else if (anchorId === 'all') {
        this.setState({ selectedAnchors: _.values(inUse.anchors) })
      } else {
        const { selectedAnchors } = this.state
        const anchor = { ...inUse.anchors[anchorId] }
        let newSelectedAnchorArray = [...selectedAnchors]

        if (
          newSelectedAnchorArray.some(
            (selectedAnchor) => anchor.id == selectedAnchor.id
          )
        ) {
          newSelectedAnchorArray = newSelectedAnchorArray.filter(
            (selectedAnchor) => anchor.id != selectedAnchor.id
          )
        } else {
          newSelectedAnchorArray.push(anchor)
        }

        this.setState({ selectedAnchors: newSelectedAnchorArray })
      }
    }

    /*============= Update header options =========*/

    setOptions = (options) => {
      this.setState({ options })
    }

    /*========= Actions ==========*/

    updateMaster = (name, value) => {
      this.props.updateMaster(value)
    }

    /*======= Hardware controls ====*/

    serviceAction = (tagIds, type) => {
      this.props.serviceAction(tagIds, type)
    }

    /*========== Render ===========*/

    render() {
      const { fetchingPitch, options } = this.state

      const { pitches, hardware, router, version, user } = this.props

      const { inUse } = pitches

      const { pitch, anchorConfig } = getStrackPitchAndAnchorConfig(inUse)

      const isAdmin = user.data.permissionLevel >= permissionLevels.admin

      return (
        <ChildComponent
          {...this.props}
          isAdmin={isAdmin}
          // UI propp
          ToolbarInner={null}
          backButton={{
            text: 'Back',
            route: router.prevRoute
          }}
          title='Diagnostics'
          setOptions={this.setOptions}
          options={options}
          // Anchor Health
          user={user}
          pitches={pitches}
          pitch={pitch}
          anchorConfig={anchorConfig}
          hardware={hardware}
          version={version}
          fetchingPitch={fetchingPitch}
          // Diags Table
          // Service
          serviceAction={this.serviceAction}
          enableRSSIAction={this.props.enableRSSIAction}
          disableRSSIAction={this.props.disableRSSIAction}
        />
      )
    }
  }

  function mapStateToProps(state) {
    return {
      pitches: state.pitches,
      hardware: state.hardware,
      version: state.version,
      user: state.user
    }
  }

  return connect(mapStateToProps, actions)(ComposedComponent)
}
