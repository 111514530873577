import { useMemo } from 'react'
import { useSession } from '../../sessions/hooks'
import { useEventsFilters } from '../filter'
import {
  getCurrentPeriod,
  getStoppages,
  isInPlay,
  isPlayStopped
} from './functions'
import { TimeEventTypeKeys } from './data_types'

export function useTimeEvents() {
  // Session //
  const sessionConfig = useSession()
  const { timeEventTypes } = sessionConfig
  // ======= //

  const timeEvents = useEventsFilters({}, ['time'])

  const playStopped = isPlayStopped(timeEvents.filteredEvents)

  const activeStoppage = useMemo(() => {
    const stoppages = getStoppages(timeEvents.filteredEvents)

    return stoppages.find((x) => !x.timeEnd)
  }, [timeEvents])

  const inPlay = isInPlay(timeEvents.filteredEvents, timeEventTypes)

  const { currentPeriod, currentPeriodTimeEventType, nextPeriodTimeEventType } =
    useCurrentPeriod()

  const fullTime =
    currentPeriod && !nextPeriodTimeEventType && currentPeriod.timeEnd

  return {
    activeStoppage,
    playStopped,
    inPlay,
    fullTime,
    currentPeriod,
    currentPeriodTimeEventType,
    nextPeriodTimeEventType,
    timeEventTypes
  }
}

export function useCurrentPeriod() {
  // Session //
  const { sport } = useSession()
  const timeEventTypes = sport.props.eventTypes.items.time.props.types
  // ======= //

  const timeEvents = useEventsFilters({}, ['time'])

  const currentPeriod = useMemo(() => {
    return getCurrentPeriod(timeEvents.filteredEvents, timeEventTypes)
  }, [timeEventTypes, timeEvents.filteredEvents])

  const currentPeriodTimeEventType = useMemo(() => {
    return timeEventTypes.getTypeByValue(currentPeriod?.type)
  }, [currentPeriod?.type, timeEventTypes])

  const nextPeriodTimeEventType = useMemo(() => {
    let nextPeriod = null
    for (const key in timeEventTypes.items) {
      const timeEventType = timeEventTypes.items[key as TimeEventTypeKeys]
      if (
        timeEventType?.props?.features?.period &&
        (timeEventType.value > currentPeriod?.type || !currentPeriod)
      ) {
        nextPeriod = timeEventType
        break
      }
    }

    return nextPeriod
  }, [currentPeriod])

  return {
    currentPeriod,
    currentPeriodTimeEventType,
    nextPeriodTimeEventType
  }
}
