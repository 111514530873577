import React from 'react'
import styles from './SessionTitle.module.scss'
import { useTheme } from '../../../../ui/config/hook'
import { sportableColors } from '../../../../constants/sportableColors'
import { Scoreboard } from '../../../../components/Scoreboard/Scoreboard'
import { useFormattedSession } from '../../hooks'
import { DrillsQuickStart } from '../../../drills/components/DrillsQuickStart/DrillsQuickStart'

interface SessionTitleProps {
  sessionId: string
}

export const SessionTitle = ({ sessionId }: SessionTitleProps) => {
  const theme = useTheme()
  const { isMatchMode, isTrainingMode, sport, name, startTime, isSetup, live } =
    useFormattedSession(sessionId)
  const color = theme ? theme.appColor : sportableColors.colors.sportableBlue

  return (
    <div className={styles.tabTitleContainer}>
      <div
        style={{
          margin: '0 auto'
        }}
      >
        {sport.props.features.drillsEnabled && live && isTrainingMode && (
          <DrillsQuickStart />
        )}
        {!isSetup && isMatchMode && sport.props.features.scoreboard ? (
          <Scoreboard sessionId={sessionId} />
        ) : null}
      </div>
      <div>
        <h4 className={styles.teamTitle}>
          <span className={styles.firstTeam} style={{ color: color }}>
            {name}
          </span>
          <span className={styles.secondTeam} style={{ color: color }}>
            -
          </span>
          <span className={styles.date}>{startTime.full.formattedShort}</span>
        </h4>
      </div>
    </div>
  )
}
