import { useMemo } from 'react'
import { usePitches } from '../../metrics_server/pitches/hooks'
import { useFormattedSession } from '../../metrics_server/sessions/hooks'
import { getStrackPitchAndAnchorConfig } from '../../utils/strack/functions'
import { StrackProps } from './Strack.types'

export const useStrackProps = (): StrackProps => {
  const { inUse } = usePitches()

  return useMemo(() => {
    return getStrackPitchAndAnchorConfig(inUse)
  }, [inUse])
}

export const useSessionStrackProps = (sessionId): StrackProps => {
  const { doublePitchSetup, demonstrationPitch } = usePitches()
  const formattedSession = useFormattedSession(sessionId || 'setup')

  return useMemo(() => {
    return {
      session: formattedSession,
      pitch: formattedSession.pitch,
      anchorConfig: formattedSession.anchorConfig,
      doublePitchSetup: doublePitchSetup,
      demonstrationPitch: demonstrationPitch
    }
  }, [formattedSession, doublePitchSetup, demonstrationPitch])
}
