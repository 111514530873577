import React, { useMemo } from 'react'
import { goToLanding } from '../../ui/router/actions'
import { SessionTitle } from '../../metrics_server/sessions/components/Title/SessionTitle'

import * as StartSession from '../StartSession/config'
import { useAppDispatch } from '../../store/hooks'
import { AssignmentStatus } from '../../components/AssignmentStatus/AssignmentStatus'

export function useSessionSetupHeaderProps() {
  const dispatch = useAppDispatch()

  return useMemo(() => {
    return {
      title: <SessionTitle sessionId={'setup'} />,
      iconClick: () => {
        dispatch(goToLanding())
      },
      backButton: {
        route: StartSession.path
      },
      ToolbarInner: AssignmentStatus
    }
  }, [dispatch])
}
