import React, { useState } from 'react'
import TeamFormGeneric from '../../../components/Forms/TeamForm/TeamForm'
import { isLocal } from '../../../metrics_server/env'
import { OrganisationAndTeamForms } from '../form_config'

export interface TeamFormProps {
  newTeam: boolean
  isSetup: boolean
  isNewOrg: boolean
  switchForm: (form: OrganisationAndTeamForms) => void
  goBack: () => void
  createTeam: (values, hideForm) => void
}

export const CreateTeamForm = (props: TeamFormProps) => {
  const { newTeam, isSetup, isNewOrg, switchForm, goBack } = props

  const [loading, setLoading] = useState(false)

  const handleSecondaryBtn = () => {
    if (isLocal) {
      goBack()
    } else if (isSetup && isNewOrg) {
      switchForm(OrganisationAndTeamForms.joinTeam)
    } else {
      switchForm(OrganisationAndTeamForms.joinTeam)
    }
  }

  const hideForm = () => {
    props.switchForm(null)
  }

  const handleSubmit = (values) => {
    setLoading(true)
    props.createTeam(values, hideForm)
  }
  return (
    <TeamFormGeneric
      newTeam={newTeam}
      handleProceed={handleSubmit}
      handleSecondaryBtn={handleSecondaryBtn}
      loading={loading}
    />
  )
}
