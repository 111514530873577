import React from 'react'
import { AnchorHealthGridItem } from './AnchorHealthGridItem'
import { useHardware } from '../../hooks'
import styles from './AnchorHealthGrid.module.scss'

export const AnchorHealthGrid = () => {
  const { formattedHardware } = useHardware()

  return (
    <div className={styles.anchorHealthGridContainer}>
      {formattedHardware.types.anchor.devices.list.map((device, index) => {
        return (
          <AnchorHealthGridItem
            device={device}
            key={`AnchorHealthGrid ${device.id}`}
          />
        )
      })}
    </div>
  )
}
