import React, { useEffect, useState } from 'react'
import styles from '../SessionSetup.module.scss'
import { CardList } from '../../../components/CardList/CardList'
import Loader from '../../../components/Loader/Loader'
import { usePitches } from '../../../metrics_server/pitches/hooks'
import { useFormattedSession } from '../../../metrics_server/sessions/hooks'
import PitchSetupForm from '../../../components/Forms/PitchSetupForm'
import { pitchSetupTypes } from '../../../metrics_server/pitches/data_types'
import { AnchorHealthGrid } from '../../../metrics_server/hardware/components/AnchorHealthGrid/AnchorHealthGrid'
import { isDoublePitchSetup } from '../../../metrics_server/pitches/functions'
import { useAppDispatch } from '../../../store/hooks'
import {
  setDemonstrationPitch,
  setDoublePitchSetup
} from '../../../metrics_server/pitches/actions'
import Checkbox from '../../../components/Forms/Inputs/Checkbox/Checkbox'

export function PitchSetup({ strack, strackReady, setResetStrack }) {
  const dispatch = useAppDispatch()

  const pitches = usePitches()
  const formattedSession = useFormattedSession('setup')
  const { pitch } = formattedSession

  useEffect(() => {
    dispatch(
      setDoublePitchSetup(isDoublePitchSetup(pitch.type, pitch.coordinates))
    )
  }, [])

  // Redraw when pitch changes
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    if (isMounted) {
      dispatch(
        setDoublePitchSetup(isDoublePitchSetup(pitch.type, pitch.coordinates))
      )
      setResetStrack(true)
    } else {
      setIsMounted(true)
    }
  }, [pitch])

  // Redraw pitch when double pitch setup changes
  useEffect(() => {
    if (isMounted) {
      setResetStrack(true)
    } else {
      setIsMounted(true)
    }
  }, [pitches.doublePitchSetup, pitches.demonstrationPitch])

  // TODO: Remove when roles are introduced
  const isAdmin = true

  // Strack Functions //
  useEffect(() => {
    if (strackReady) {
      strack.generateSetupCanvas(() => {
        if (pitches.setupType === pitchSetupTypes.items.auto.value) {
          strack.drawPitchAutosetup(pitches.side)
        } else {
          strack.drawPitchAutosetup(null)
        }
      })
    }
  }, [strackReady])

  useEffect(() => {
    if (strack) {
      strack.drawPitchAutosetup(pitches.side)
    }
  }, [pitches.side])

  useEffect(() => {
    if (strack) {
      if (pitches.setupType === pitchSetupTypes.items.auto.value) {
        strack.drawPitchAutosetup(pitches.side)
      } else {
        strack.drawPitchAutosetup(null)
      }
    }
  }, [pitches.setupType])
  // ================= //

  return (
    <div className={styles.pitchViewContainer}>
      <div
        style={{ height: isAdmin ? '64%' : '48%' }}
        className={styles.broadcastControlsContainer}
      >
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={styles.analysisButtonContainer}>
            {pitches.autoLocateActive ? (
              <div className={styles.autolocateLoader}>
                <Loader />
                <p>Autolocating Anchors...</p>
              </div>
            ) : (
              <PitchSetupForm />
            )}
          </div>
        </CardList>
      </div>
      <div
        style={{ height: isAdmin ? '36%' : '52%' }}
        className={styles.broadcastAnchorsContainer}
      >
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={styles.containerInner}>
            <AnchorHealthGrid />
          </div>
        </CardList>
      </div>
    </div>
  )
}
