import React, { Component } from 'react'
import Row from './Row'
import styles from './Table.module.scss'
import {
  TableCheckbox,
  TableControl,
  TableHeader,
  TableProps,
  TableRow
} from './Table.types'

interface TableState {
  filterBy: string
  order: string
  sortedData: TableRow[]
  currentPage: number
  rowsPerPage: number
  rowHeight: number
  searchValue?: string
  checkedCheckboxes?: string[]
}

export const getRowId = (id: string, tableId: string) => {
  if (!id) return null
  return id + '-' + tableId
}

export const getItemIdFromRowId = (rowId: string) => {
  if (!rowId) return null
  return rowId.split('-')[0]
}

export const isBorder = (currentHeader, nextHeader) => {
  if (!nextHeader) return true
  return currentHeader.group !== nextHeader.group
}

export class Table extends Component<TableProps, TableState> {
  state: TableState = {
    filterBy: '',
    order: '',
    sortedData: [],
    currentPage: 1,
    rowsPerPage: 0,
    rowHeight: 29,
    searchValue: '',
    checkedCheckboxes: []
  }

  componentDidMount() {
    const { data, pagination } = this.props
    const sortedData = this.sortData(data)
    this.setState({ sortedData })

    if (pagination && sortedData) {
      this.calculateRowsPerPage()
    }

    document.addEventListener('keydown', this.tableScrollShortcut)
  }

  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.tableScrollShortcut)
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, newRows, highlightedRow, pagination, onPageChange } =
      this.props
    const {
      filterBy,
      order,
      currentPage,
      rowHeight,
      searchValue,
      checkedCheckboxes
    } = this.state

    if (
      prevState.filterBy !== filterBy ||
      prevState.order !== order ||
      prevProps.data !== data ||
      prevProps.newRows !== newRows ||
      prevState.searchValue !== searchValue ||
      prevState.checkedCheckboxes !== checkedCheckboxes
    ) {
      const sortedData = this.sortData(data)
      const displayedRows = this.getDisplayedRows()
      this.setState({ sortedData })
      if (onPageChange) onPageChange(displayedRows)
    }
    // skip to page with highlited row
    if (
      pagination &&
      highlightedRow &&
      highlightedRow !== prevProps.highlightedRow
    ) {
      const highligtedEventPage =
        this.getHighlitedEventPageNumber(highlightedRow)
      if (highligtedEventPage !== currentPage && highligtedEventPage >= 1) {
        this.handlePageChange(highligtedEventPage)
      }
    }
    // calculate rows per page when row height changes
    if (pagination && rowHeight !== prevState.rowHeight) {
      this.calculateRowsPerPage()
    }

    if (pagination && currentPage !== prevState.currentPage) {
      const displayedRows = this.getDisplayedRows()
      if (onPageChange) onPageChange(displayedRows)
    }
  }

  sortData = (data: TableRow[]) => {
    const { filterBy, order, searchValue, checkedCheckboxes } = this.state
    const { id, generateRowData, headers, searchBy, checkBoxes } = this.props
    const { initialSortBy, initialOrder } = this.props.options || {}

    let filteredData = data

    // Filter data based on checked checkboxes
    filteredData = this.filterDataByCheckboxes(data)

    // Apply search filtering if searchValue is not empty
    if (searchValue.trim() !== '') {
      filteredData = data.filter((row) =>
        searchBy.some((key) => {
          return (
            Object.prototype.hasOwnProperty.call(row, key) &&
            row[key] !== undefined && // Check if key exists
            row[key] !== null && // Check if key is not null
            row[key].toLowerCase().includes(searchValue.toLowerCase())
          )
        })
      )
    }

    const filterByHeader = headers.find(
      (x) => x.key === (filterBy || initialSortBy)
    )

    filteredData = filteredData.map((x) => {
      const row = { ...x }
      row.tableId = getRowId(row.id, id)
      return row
    })

    function dec(a: TableRow, b: TableRow, key: string): number {
      const rowA = generateRowData ? generateRowData(a) : a
      const rowB = generateRowData ? generateRowData(b) : b
      let aVal = rowA[key] === undefined ? -Infinity : rowA[key]
      let bVal = rowB[key] === undefined ? -Infinity : rowB[key]

      if (filterByHeader?.type === 'duration') {
        // Calculate actual duration in seconds for comparison
        const getDurationDiff = (val) => {
          if (!val?.startTime || !val?.endTime) return -Infinity
          return val.endTime - val.startTime
        }
        aVal = getDurationDiff(aVal)
        bVal = getDurationDiff(bVal)
      } else if (
        filterByHeader?.type === 'date' ||
        filterByHeader?.type === 'time'
      ) {
        if (typeof rowA[key] === 'string') aVal = -Infinity
        if (typeof rowB[key] === 'string') bVal = -Infinity
      }

      if (aVal < bVal) return 1
      if (aVal > bVal) return -1
      return 0
    }

    function asc(a: TableRow, b: TableRow, key: string): number {
      const rowA = generateRowData ? generateRowData(a) : a
      const rowB = generateRowData ? generateRowData(b) : b
      let aVal = rowA[key] === undefined ? -Infinity : rowA[key]
      let bVal = rowB[key] === undefined ? -Infinity : rowB[key]

      if (filterByHeader?.type === 'duration') {
        // Calculate actual duration in seconds for comparison
        const getDurationDiff = (val) => {
          if (!val?.startTime || !val?.endTime) return -Infinity
          return val.endTime - val.startTime
        }
        aVal = getDurationDiff(aVal)
        bVal = getDurationDiff(bVal)
      } else if (
        filterByHeader?.type === 'date' ||
        filterByHeader?.type === 'time'
      ) {
        if (typeof rowA[key] === 'string') aVal = -Infinity
        if (typeof rowB[key] === 'string') bVal = -Infinity
      }

      if (aVal > bVal) return 1
      if (aVal < bVal) return -1
      return 0
    }

    if (!filterBy || !order) {
      const sortedData = filteredData.sort(
        initialOrder === 'dec'
          ? (a, b) => dec(a, b, initialSortBy)
          : (a, b) => asc(a, b, initialSortBy)
      )
      const bottomItems = sortedData.filter((item) => item.__bottom)
      const regularItems = sortedData.filter((item) => !item.__bottom)
      return [...regularItems, ...bottomItems]
    }

    const regularItems = filteredData.filter((item) => !item.__bottom)
    const bottomItems = filteredData.filter((item) => item.__bottom)

    const sortedRegularItems = regularItems.sort(
      order === 'dec'
        ? (a, b) => dec(a, b, filterBy)
        : (a, b) => asc(a, b, filterBy)
    )

    return [...sortedRegularItems, ...bottomItems]
  }

  sortBy = (key: string) => {
    const { filterBy, order } = this.state
    let state = {}
    if (key === filterBy) {
      if (order === 'asc') state = { order: 'dec' }
      else state = { filterBy: '', order: '' }
    } else {
      state = state = { filterBy: key, order: 'asc' }
    }
    this.setState(state)
  }

  getSelectedRowData = () => {
    const { data, highlightedRow } = this.props
    const rowData = data.find((x) => x.tableId === highlightedRow)
    return rowData
  }

  renderHeads = (head: TableHeader, index: number) => {
    const { filterBy, order } = this.state
    const { headers, headerFont, options, groups } = this.props
    const group = groups?.[head.group]
    const currentIndex = headers.findIndex((h) => h.key === head.key)
    const previousHeader = headers[currentIndex - 1]
    const nextHeader = headers[currentIndex + 1]
    return (
      <th
        style={{
          width: head.width ? `${head.width}%` : `${100 / headers.length}%`,
          fontSize: `${headerFont || 15}px`,
          position: 'relative',
          borderTop: group ? `${group?.color} 3px solid` : 'none',
          borderRight:
            group && isBorder(head, nextHeader)
              ? `${group?.color} 3px solid`
              : 'none',
          borderLeft:
            group && isBorder(head, previousHeader)
              ? `${group?.color} 3px solid`
              : 'none'
        }}
        onClick={() => {
          if (options.sortActive) this.sortBy(head.key)
        }}
        key={`${head.key}${index}`}
      >
        <div className={styles.tableHeader}>
          {head.headerColor ? (
            <div
              style={{ background: head.headerColor }}
              className={styles.color}
            ></div>
          ) : (
            <div>{head.type === 'button' ? '' : head.name}</div>
          )}
        </div>
        {filterBy === head.key && (
          <div className={styles.sortMarker}>
            {order === 'asc' && <div>&#9652;</div>}
            {order === 'dec' && <div>&#9662;</div>}
          </div>
        )}
      </th>
    )
  }
  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value })
  }

  renderControl = (option: TableControl, index: number) => {
    if (option.hidden) return <noscript />
    return (
      <button
        className={`${styles.option} link button`}
        key={index}
        onClick={() => {
          const item = this.getSelectedRowData()
          option.callback(item)
        }}
      >
        {option.name}
      </button>
    )
  }
  // Method to filter data based on checked checkboxes
  filterDataByCheckboxes = (data: TableRow[]) => {
    const { checkBoxes, id } = this.props
    const { checkedCheckboxes } = this.state

    if (!checkBoxes) {
      return data
    }

    if (checkedCheckboxes.length === 0) {
      return data // Return all data if no checkboxes are checked
    }

    return data.filter((row) => {
      // Filter data based on whether checkbox option exists in the row data
      return checkedCheckboxes.every((checkboxKey) => {
        const checkbox = checkBoxes.find((checkbox) => {
          if (typeof checkbox === 'object') {
            return checkbox.key === checkboxKey
          } else {
            return checkbox === checkboxKey
          }
        })

        if (!checkbox) return false

        if (typeof checkbox === 'object') {
          return checkbox.filter(row)
        } else {
          return row[checkbox]
        }
      })
    })
  }

  renderCheckbox = (option: TableCheckbox, index: number) => {
    if (!option) return <noscript />
    if (typeof option === 'object') {
      return (
        <div
          key={index}
          style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}
        >
          <input
            type='checkbox'
            style={{
              transform: 'scale(1)',
              cursor: 'pointer',
              padding: 0,
              height: '17px',
              width: '17px',
              marginRight: '7px'
            }}
            checked={this.state.checkedCheckboxes.includes(option.key)}
            onChange={() => {
              this.handleCheckboxChange(option.key)
            }}
          />
          <span style={{ fontSize: '13px' }}>{option.label}</span>
        </div>
      )
    } else {
      return (
        <div
          key={index}
          style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}
        >
          <input
            type='checkbox'
            style={{
              transform: 'scale(1)',
              cursor: 'pointer',
              padding: 0,
              height: '17px',
              width: '17px',
              marginRight: '7px'
            }}
            checked={this.state.checkedCheckboxes.includes(option)}
            onChange={() => {
              this.handleCheckboxChange(option)
            }}
          />
          <span style={{ fontSize: '13px' }}>{option}</span>
        </div>
      )
    }
  }

  // Method to handle checkbox click
  handleCheckboxChange = (newCheckboxKey: string) => {
    const { checkedCheckboxes } = this.state
    let newCheckedCheckboxes = [...checkedCheckboxes]

    if (newCheckedCheckboxes.includes(newCheckboxKey)) {
      // Checkbox was checked, uncheck it
      newCheckedCheckboxes = newCheckedCheckboxes.filter(
        (checkboxKey) => checkboxKey !== newCheckboxKey
      )
    } else {
      // Checkbox was unchecked, check it
      newCheckedCheckboxes.push(newCheckboxKey)
    }

    this.setState({ checkedCheckboxes: newCheckedCheckboxes })
  }

  renderRows = (data: TableRow, index: number) => {
    const {
      editCell,
      editedRows,
      updateEditedRows,
      isEditableTable,
      deletedRows,
      highlightedRow,
      highlightRow,
      headers,
      generateRowData,
      id,
      groups,
      tableSize
    } = this.props
    const isLastRow = this.state.sortedData.length - 1 === index

    if (isEditableTable && editedRows[data.tableId])
      data = editedRows[data.tableId]
    if (isEditableTable && deletedRows[data.tableId])
      data = deletedRows[data.tableId]

    return (
      <Row
        isEditableTable={isEditableTable}
        updateEditedRows={updateEditedRows}
        setEditCell={this.props.setEditCell}
        updateCellValue={this.props.updateCellValue}
        editCell={editCell}
        highlightedRow={highlightedRow}
        highlightRow={highlightRow}
        key={`${data.tableId}-${index}`}
        headers={headers}
        item={data}
        tableId={id}
        generateRowData={generateRowData}
        groups={groups}
        isLastRow={isLastRow}
        getRowHeight={this.getRowHeight}
        tableSize={tableSize}
      />
    )
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { data, highlightedRow, highlightedColumn, headers, editCell } =
      this.props

    const nextData = nextProps.data
    const nextHeaders = nextProps.headers

    // local component state changes update component
    if (this.state !== nextState) {
      // console.log('state change')
      return true
    }

    // Update if highlight row changes
    if (highlightedRow !== nextProps.highlightedRow) {
      // console.log('highlighted row change')
      return true
    }
    if (highlightedColumn !== nextProps.highlightedColumn) {
      // console.log('highlighted column change')
      return true
    }

    // Update if editCell changes
    if (editCell !== nextProps.editCell) {
      // console.log('edit cell change')
      return true
    }

    // Update table if header change
    if (headers !== nextHeaders) {
      // console.log('headers change')
      return true
    }

    // Update table if data changes
    // console.log(data !== nextData)
    return data !== nextData
  }

  getIndexFromRowId = (rowId: string) => {
    const { sortedData } = this.state
    return sortedData.findIndex((row) => {
      return row.tableId === rowId
    })
  }

  getHighlitedEventPageNumber = (highlightedRow) => {
    const { pagination } = this.props
    const { rowsPerPage } = this.state
    const highlightedIndex = this.getIndexFromRowId(highlightedRow)
    if (pagination && rowsPerPage !== 0 && highlightedIndex >= 0)
      return Math.floor(highlightedIndex / rowsPerPage) + 1
  }

  tableScrollShortcut = (event) => {
    const {
      scrollShortcutsEnabled,
      handleShiftUpShortcut,
      handleShiftDownShortcut,
      highlightRow,
      highlightedRow
    } = this.props
    if (scrollShortcutsEnabled) {
      if (event.repeat) return

      const { sortedData, currentPage, rowsPerPage } = this.state
      const { pagination, disableKeyboardShortcuts } = this.props
      const highlightedIndex = this.getIndexFromRowId(highlightedRow)
      const highlitedEventPageNumber =
        this.getHighlitedEventPageNumber(highlightedRow)

      // shortcut for most recent kick [shift, ArrowUp]
      if (event.shiftKey) {
        if (event.key === 'ArrowUp' && handleShiftUpShortcut) {
          event.preventDefault()
          if (event.repeat) return
          handleShiftUpShortcut()
        } else if (event.key === 'ArrowDown' && handleShiftDownShortcut) {
          event.preventDefault()
          if (event.repeat) return
          handleShiftDownShortcut()
        }
      } else {
        // standard scrolling up/down using arrow key shortcuts
        if (event.key === 'ArrowDown') {
          if (disableKeyboardShortcuts && disableKeyboardShortcuts.down) return
          event.preventDefault()
          if (event.repeat) return
          if (highlightedIndex + 1 < sortedData.length) {
            if (highlightedRow && currentPage !== highlitedEventPageNumber) {
              this.handlePageChange(highlitedEventPageNumber)
            } else {
              // Check that we are not exceeding the array bounds
              const nextRow = sortedData[highlightedIndex + 1]
              if (nextRow) {
                // Check that nextRow is not undefined
                highlightRow(nextRow.tableId, nextRow)
              }
            }
          }
        }

        if (event.key === 'ArrowUp') {
          if (disableKeyboardShortcuts && disableKeyboardShortcuts.up) return
          event.preventDefault()
          if (event.repeat) return
          if (highlightedIndex - 1 >= 0) {
            if (highlightedRow && currentPage !== highlitedEventPageNumber) {
              this.handlePageChange(highlitedEventPageNumber)
            } else {
              // Check that we are not going below 0 index
              const nextRow = sortedData[highlightedIndex - 1]
              if (nextRow) {
                // Check that nextRow is not undefined
                highlightRow(nextRow.tableId, nextRow)
              }
            }
          }
        }
        if (pagination && sortedData.length > rowsPerPage) {
          if (event.key === 'ArrowRight') {
            if (disableKeyboardShortcuts && disableKeyboardShortcuts.right)
              return
            event.preventDefault()
            if (event.repeat) return
            this.handleRightArrowPageChange()
          }
          if (event.key === 'ArrowLeft') {
            if (disableKeyboardShortcuts && disableKeyboardShortcuts.right)
              return
            event.preventDefault()
            if (event.repeat) return
            this.handleLeftArrowPageChange()
          }
        }
      }
    }
  }

  calculateIndices = () => {
    const { currentPage, rowsPerPage, sortedData } = this.state
    let startIdx = (currentPage - 1) * rowsPerPage
    let endIdx = startIdx + rowsPerPage

    // Ensure startIdx and endIdx are within bounds
    startIdx = Math.max(0, Math.min(startIdx, sortedData.length))
    endIdx = Math.max(0, Math.min(endIdx, sortedData.length))

    const lastPage = Math.ceil(sortedData.length / rowsPerPage)
    // when table is filtered change page number if it no longer exists
    if (currentPage > lastPage && lastPage > 0) {
      this.setState({ currentPage: 1 })
    }

    return { startIdx, endIdx, lastPage }
  }
  calculateRowsPerPage = () => {
    const { rowHeight } = this.state
    const { id } = this.props
    const table = document.getElementById(id)
    const tableHeight = table.clientHeight
    const rowsPerPage = Math.floor(tableHeight / rowHeight)
    if (table && rowsPerPage && tableHeight) {
      this.setState({ rowsPerPage: rowsPerPage - 1 })
    }
  }
  getRowHeight = (rowHeight) => {
    this.setState({ rowHeight: rowHeight })
  }
  handlePageChange = (newPage) => {
    this.setState({ currentPage: newPage })
  }
  handleRightArrowPageChange = () => {
    const { sortedData, currentPage, rowsPerPage } = this.state
    const { highlightRow, highlightedRow } = this.props
    const lastPage = Math.ceil(sortedData.length / rowsPerPage)
    const highlightedIndex = this.getIndexFromRowId(highlightedRow)

    if (currentPage !== lastPage) {
      // highlight event next page
      const nextPageRowIndex =
        highlightedIndex + rowsPerPage >= sortedData.length - 1
          ? sortedData.length - 1
          : highlightedIndex + rowsPerPage
      const nextPageRow = sortedData[nextPageRowIndex]
      this.handlePageChange(this.state.currentPage + 1)
      highlightRow(nextPageRow.tableId, nextPageRow)
    }
  }
  handleLeftArrowPageChange = () => {
    const { currentPage, sortedData, rowsPerPage } = this.state
    const { highlightRow, highlightedRow } = this.props
    const highlightedIndex = this.getIndexFromRowId(highlightedRow)

    // check if it's first page
    if (currentPage !== 1) {
      const nextPageRow = sortedData[highlightedIndex - rowsPerPage]
      this.handlePageChange(this.state.currentPage - 1)
      highlightRow(nextPageRow.tableId, nextPageRow)
    }
  }
  handleDoubleArrowPageChange = (direction) => {
    const { sortedData, rowsPerPage } = this.state
    const lastPage = Math.ceil(sortedData.length / rowsPerPage)
    const { highlightRow } = this.props
    const lastRow = sortedData[sortedData.length - 1]
    const firstRow = sortedData[0]
    if (direction === 'right') {
      this.handlePageChange(lastPage)
      highlightRow(lastRow.tableId, lastRow)
    }
    if (direction === 'left') {
      this.handlePageChange(1)
      highlightRow(firstRow.tableId, firstRow)
    }
  }

  getDisplayedRows = () => {
    let { sortedData } = this.state
    const { newRows, pagination } = this.props

    if (newRows) sortedData = [...Object.values(newRows), ...sortedData]
    const { startIdx, endIdx } = this.calculateIndices()

    const displayedRows = !pagination
      ? sortedData
      : sortedData.slice(startIdx, endIdx)

    return displayedRows
  }

  render() {
    const {
      title,
      headers,
      tableClass,
      id,
      controls,
      className,
      smallHead,
      newRows,
      pagination,
      searchBy,
      checkBoxes
    } = this.props
    let { sortedData } = this.state
    const { currentPage, rowsPerPage } = this.state

    if (newRows) sortedData = [...Object.values(newRows), ...sortedData]
    const { endIdx, lastPage } = this.calculateIndices()

    const displayedRows = pagination ? this.getDisplayedRows() : sortedData

    const containerStyle = {
      height: '100%'
    }

    if (controls || title || searchBy || checkBoxes) {
      containerStyle.height = 'calc(100% - 25px)'
    }

    return (
      <div
        className={`${styles.container} ${styles[className]}`}
        data-testid='table-container'
      >
        {(controls || title || searchBy || checkBoxes) && (
          <div
            className={styles.headerContainer}
            style={{
              paddingBottom:
                searchBy && controls ? '10px' : searchBy ? '15px' : '0',
              boxSizing: 'border-box'
            }}
          >
            {title && (
              <div className={styles.titleContainer}>
                <h5>{title}</h5>
              </div>
            )}
            <div className={styles.checkBoxesContainer}>
              {checkBoxes && checkBoxes.map(this.renderCheckbox)}
            </div>
            <div className={styles.optionsContainer}>
              <div style={{ marginRight: searchBy ? '10px' : undefined }}>
                {controls && controls.map(this.renderControl)}
              </div>
              {searchBy && searchBy.length > 0 && (
                <input
                  type='text'
                  value={this.state.searchValue}
                  onChange={this.handleSearchChange}
                  placeholder='Search...'
                  key='search'
                  style={{
                    outline: 'none',
                    borderRadius: '5px',
                    border: '1px solid #c5c4c8',
                    fontSize: '14px',
                    minHeight: '25px',
                    width: '150px',
                    padding: 'inherit',
                    paddingLeft: '5px'
                  }}
                />
              )}
            </div>
          </div>
        )}
        <div style={containerStyle} className={styles.tableContainer}>
          <div
            className={
              smallHead ? styles.smallHeadContainer : styles.headContainer
            }
          >
            <table
              className={`${styles.tableHead} ${styles[tableClass]}`}
              data-testid='table'
            >
              <thead>
                <tr>{headers.map(this.renderHeads)}</tr>
              </thead>
            </table>
          </div>
          <div
            className={smallHead ? styles.smallHeadOverflow : styles.overflow}
          >
            <div id={id} className={styles.bodyContainer}>
              <table className={styles[tableClass]}>
                <tbody>{displayedRows.map(this.renderRows)}</tbody>
              </table>
              {/* Pagination controls */}
              {pagination && sortedData.length > rowsPerPage && (
                <div className={styles.paginationControls}>
                  <button
                    onClick={() => this.handleDoubleArrowPageChange('left')}
                    disabled={currentPage === 1}
                    style={{ cursor: 'pointer' }}
                  >
                    {'<<'}
                  </button>
                  <button
                    onClick={() => this.handleLeftArrowPageChange()}
                    disabled={currentPage === 1}
                    style={{ cursor: 'pointer' }}
                  >
                    {'<'}
                  </button>
                  <div>{currentPage}</div>
                  <button
                    onClick={() => this.handleRightArrowPageChange()}
                    disabled={endIdx >= sortedData.length}
                    style={{ cursor: 'pointer' }}
                  >
                    {'>'}
                  </button>
                  <button
                    onClick={() => this.handleDoubleArrowPageChange('right')}
                    disabled={currentPage === lastPage}
                    style={{ cursor: 'pointer' }}
                  >
                    {'>>'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
