import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useAppDispatch } from '../../store/hooks'
import { teamActions } from '../../metrics_server/teams'
import * as unitSystems from '../../metrics_server/units/unit_systems'

// Context
import { MqttContext } from '../../metrics_server/mqtt/Provider'

// Actions
import * as challengeActions from '../../metrics_server/targets/actions'
import { eventActions } from '../../metrics_server/events'
import * as sessionActions from '../../metrics_server/sessions/actions'
import * as subSessionActions from '../../metrics_server/sub_sessions/actions'
import { setRedirect } from '../../ui/router/actions'

// Hooks
import { useSession } from '../../metrics_server/sessions/hooks'
import {
  AllEventTypeKeys,
  useEventsFilters
} from '../../metrics_server/events/filter'
import { useSportscaster } from '../../metrics_server/sportscaster/hooks'
import { useUnits, useUnitsSystem } from '../../metrics_server/units/hooks'

// Types
import { UnitSystemNames } from '../../metrics_server/units/types'

// Functions
import {
  subscribeToEventTopics,
  unsubscribeFromEventTopics
} from '../../metrics_server/mqtt/functions'
import { getSelectedBroker } from '../../metrics_server/sportscaster/functions'
import { generateCodingTableEventRowData } from '../../metrics_server/events/functions'
import { useBroadcastIntegrationStatusCheck } from '../../metrics_server/broadcast_integration/hooks'
import { broadcastingActions } from '../../metrics_server/broadcasting'
import { useTeams } from '../../metrics_server/teams/hooks'
import { SortingState } from '@tanstack/react-table'
import { CodingTableRow } from '../../metrics_server/events/game/functions'
import { getDrillsForSession } from '../../metrics_server/drills/thunks'

export const SessionContainer: any = (View) => {
  return (props) => {
    // Redux Store //
    const dispatch = useAppDispatch()
    const sportscaster = useSportscaster()
    const units = useUnits()
    // ===== //

    const [highlightedRow, setHighlightedRow] = useState(null)

    // Session //
    const sessionConfig = useSession()

    const { session, live, sport, isTrainingMode } = sessionConfig
    const teams = useTeams()

    // ======= //

    // Units //
    const unitSystem = useUnitsSystem(sport)
    // ===== //

    // Broadcast Integration //
    useBroadcastIntegrationStatusCheck()
    // ====================== //

    // Fetch session data //
    useEffect(() => {
      dispatch(getDrillsForSession(session.id))
      dispatch(eventActions.getSessionEvents(session.id))
      dispatch(eventActions.getSessionFlights(session.id))
      dispatch(challengeActions.getSessionChallenges(session.id))
      dispatch(subSessionActions.getSubSessions(session.id))
      dispatch(eventActions.getSessionGameEvents(session.id))
      dispatch(eventActions.getSessionTimeEvents(session.id, null))
      session.teamsSessions.forEach((teamSession) => {
        dispatch(teamActions.getTeamPlayers(teamSession.teamId))
      })

      if (live) {
        dispatch(broadcastingActions.getBroadcastState())
      }
    }, [units.selected])
    // ================== //

    // Manage MQTT //
    // Record current location & unit system to unsubscribe from topics when location or unit system is changed
    const unitRef = useRef<UnitSystemNames>(unitSystem.key)
    const mqttContext = useContext(MqttContext)

    useEffect(() => {
      const selectedBroker = getSelectedBroker(sportscaster)

      const client = selectedBroker ? mqttContext[selectedBroker.WSSHost] : null

      if (client) {
        // If unit system changes unsubscribe from old topic
        const prevUnitSystem = unitSystems[unitRef.current]
        if (prevUnitSystem && unitSystem.key !== prevUnitSystem.key)
          unsubscribeFromEventTopics(
            client,
            prevUnitSystem,
            session.locationName
          )

        // Subscribe to new flight topic
        subscribeToEventTopics(
          client,
          unitSystem,
          session.locationName,
          (data, topic) => {
            let eventType = 'Flight'
            if (data.event?.type === 7) {
              eventType = 'AussieRules'
            } else if (data.event?.type === 6) {
              eventType = 'Game'
            }

            console.log(new Date(), `New ${eventType} Event Received`, data)
            dispatch(eventActions.addEvent(data, session.id))
          }
        )
      }

      unitRef.current = unitSystem.key

      return () => {
        if (client) {
          unsubscribeFromEventTopics(client, unitSystem, session.locationName)
        }
      }
    }, [sportscaster.selectedBrokerHost, unitSystem])
    // ================== //

    // Event Filters //

    // Manage visible coding table events
    const [visibleCodingTableEvents, setVisibleCodingTableEvents] = useState<
      AllEventTypeKeys[]
    >([
      'stoppage',
      'firstPeriod',
      'secondPeriod',
      'thirdPeriod',
      'fourthPeriod',
      'overTime',
      'possession',
      'snap',
      'kickOff',
      'handOff',
      'twoPointConversion',
      'penalty',
      'tackle',
      'safety',
      'defensiveTwoPoint',
      'qbKneel',
      'qbSack',
      'qbRush',
      'lineSet',
      'huddleBreak',
      'timeOut',
      'fumble',
      'manualPass'
    ])

    const togglePossessionEvents = () => {
      if (visibleCodingTableEvents.includes('possession')) {
        setVisibleCodingTableEvents(
          visibleCodingTableEvents.filter((event) => event !== 'possession')
        )
      } else {
        setVisibleCodingTableEvents([...visibleCodingTableEvents, 'possession'])
      }
    }

    const toggleStoppageEvents = () => {
      if (visibleCodingTableEvents.includes('stoppage')) {
        setVisibleCodingTableEvents(
          visibleCodingTableEvents.filter((event) => event !== 'stoppage')
        )
      } else {
        setVisibleCodingTableEvents([...visibleCodingTableEvents, 'stoppage'])
      }
    }

    const codingEventsFilters = useEventsFilters({}, visibleCodingTableEvents)

    // Manage validation events filter //

    const validationEventsFilters = useEventsFilters({}, [
      'flight',
      'aussieRules',
      'snap',
      'handOff',
      'kickOff',
      'twoPointConversion',
      'touchDown',
      'penalty',
      'touch',
      'try',
      'tackle',
      'safety',
      'defensiveTwoPoint',
      'goalLineCrossed',
      'soccerGoal',
      'soccerBallOverLine',
      'manualPass',
      'fumble'
    ])

    // Validation table sort //

    const [timeColumn, setTimeColumn] = useState<
      'sessionStartTime' | 'startTimeMil'
    >('sessionStartTime')

    const [validationTableSorting, setValidationTableSorting] =
      useState<SortingState>([
        {
          id: timeColumn,
          desc: live
        }
      ])

    useEffect(() => {
      if (validationTableSorting.length === 0) return
      if (
        validationTableSorting[0].id === 'sessionStartTime' ||
        validationTableSorting[0].id === 'startTimeMil'
      ) {
        setValidationTableSorting([
          {
            id: timeColumn,
            desc: validationTableSorting[0].desc
          }
        ])
      }
    }, [timeColumn])

    // ================== //

    const codingTableGeniusEventRowData = (geniusEvent: {
      eventType: string
      isConfirmed: boolean
      utcTimestamp: number
    }): CodingTableRow => {
      const { eventType, utcTimestamp } = geniusEvent
      const date = new Date(utcTimestamp)
      return {
        typeName: eventType + ' - Genius Event',
        type: null,
        subType: null,
        startTime: date.getTime(),
        id: `${eventType}${utcTimestamp}-geniusEvent`,
        eventId: `${eventType}${utcTimestamp}-geniusEvent`,
        __hidden: {
          deleteTimeEvent: true
        },
        teamLogo: {
          logo: '',
          color: 'black'
        }
      }
    }

    // Convert event data into coding table data
    const codingTableData = useMemo(() => {
      const data = generateCodingTableEventRowData(
        codingEventsFilters.filteredEvents,
        sessionConfig
      )

      // Hack - adding genius events to the coding table
      const geniusEventTableData = sportscaster.events
        .filter(
          (geniusEvent: any) => geniusEvent.eventType === 'AutoEventPassForward'
        )
        .map((geniusEvent: any) => codingTableGeniusEventRowData(geniusEvent))

      return [...data, ...geniusEventTableData]
    }, [codingEventsFilters, sportscaster.events])

    // Table functions //
    const scrollToFlight = (selectedFlightId, tableId) => {
      let table = document.getElementById(tableId)
      if (table) {
        setTimeout(() => {
          const row = document.getElementById(`${tableId}-${selectedFlightId}`)
          if (row) {
            row.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }
        })
      }
    }
    // ============== //

    // Session actions //
    const stopSession = (sessionId) => {
      dispatch(
        sessionActions.stopSession(sessionId, () => {
          dispatch(setRedirect('/activitylist'))
        })
      )
    }
    // ============= //

    return (
      <View
        {...props}
        live={live}
        // Selected Events
        setHighlightedRow={setHighlightedRow}
        highlightedRow={highlightedRow}
        // Events
        validationEventsFilters={validationEventsFilters}
        codingEventsFilters={codingEventsFilters}
        codingTableData={codingTableData}
        togglePossessionEvents={togglePossessionEvents}
        toggleStoppageEvents={toggleStoppageEvents}
        visibleCodingTableEvents={visibleCodingTableEvents}
        validationTableSorting={validationTableSorting}
        setValidationTableSorting={setValidationTableSorting}
        timeColumn={timeColumn}
        setTimeColumn={setTimeColumn}
        // To move down to components or actions
        stopSession={stopSession}
        // Set up table scroll hook?
        scrollToFlight={scrollToFlight}
      />
    )
  }
}
