import { Sport, SportTypeKeys } from '../metrics_server/sports/data_types'
import { isSoccer } from '../metrics_server/sports/functions'
import { AppTypeCheck } from '../metrics_server/user/types'

export type ShortcutScreenType = {
  screenName: string
  shortcuts: Array<Shortcut>
}

export type Shortcut = {
  key: Array<string>
  action: { default?: string; [key: string]: string } | string
}

export const getShortcuts = (
  appTypeCheck: AppTypeCheck,
  isTrainingMode: boolean,
  sport: Sport | undefined
) => {
  const shortcuts: Array<ShortcutScreenType> = [
    {
      screenName: 'Validation - Table',
      shortcuts: [
        { key: ['Tab'], action: 'Toggle ignore flight' },
        { key: ['f'], action: 'Favourite Flight' },
        { key: ['↑'], action: 'Scroll up' },
        { key: ['↓'], action: 'Scroll down' },
        !isTrainingMode && {
          key: ['Shift', ' ↑'],
          action: `Scroll to the most recent ${
            !isSoccer(sport) ? 'kick' : 'pass'
          }`
        },
        !isTrainingMode && {
          key: ['q', '1'],
          action: 'Assign event to Team A'
        },
        !isTrainingMode && { key: ['q', '2'], action: 'Assign event to Team B' }
      ]
    },
    {
      screenName: 'Validation - Kick Detail Card',
      shortcuts: [
        {
          key: ['a'],
          action: {
            rugbyUnion: 'Retained outcome (kick only)',
            soccer: 'Pass complete'
          }
        },
        {
          key: ['c'],
          action: {
            rugbyUnion: 'Contested Lost outcome (kick only)',
            canadianFootball: 'Pass complete',
            americanFootball: 'Pass complete'
          }
        },
        {
          key: ['u'],
          action: {
            rugbyUnion: 'Uncontested outcome (kick only)'
          }
        },
        {
          key: ['w'],
          action: {
            rugbyUnion: 'Won outcome (lineout only)'
          }
        },
        {
          key: ['l'],
          action: {
            rugbyUnion: 'Lost outcome (lineout only)'
          }
        },
        {
          key: ['x'],
          action: {
            canadianFootball: 'Pass incomplete',
            americanFootball: 'Pass incomplete',
            soccer: 'Pass incomplete'
          }
        },
        {
          key: ['i'],
          action: {
            canadianFootball: 'Interception',
            americanFootball: 'Interception'
          }
        }
      ]
    },
    {
      screenName: 'Validation - Assign players to flight',
      shortcuts: [
        {
          key: ['p'],
          action: {
            default: 'Hold `p`, enter player number, release `p`'
          }
        },
        {
          key: ['t'],
          action: {
            canadianFootball:
              "Hold `t`, enter target player's number, release `t`",
            americanFootball:
              "Hold `t`, enter target player's number, release `t`"
          }
        },
        {
          key: ['r'],
          action: {
            canadianFootball: "Hold `r`, enter receiver's number, release `r`",
            americanFootball: "Hold `r`, enter receiver's number, release `r`"
          }
        }
      ]
    }
  ]

  if (!isTrainingMode) {
    shortcuts.push({
      screenName: 'Coding - Manual Events',
      shortcuts: [
        {
          key: ['p'],
          action: {
            default: 'Manual Pass'
          }
        },
        {
          key: ['d'],
          action: {
            canadianFootball: 'QB Rush',
            americanFootball: 'QB Rush'
          }
        },
        {
          key: ['g'],
          action: {
            canadianFootball: 'QB Sack',
            americanFootball: 'QB Sack'
          }
        },
        {
          key: ['w'],
          action: {
            canadianFootball: 'Huddle Break',
            americanFootball: 'Huddle Break'
          }
        },
        {
          key: ['e'],
          action: {
            canadianFootball: 'Line Set',
            americanFootball: 'Line Set'
          }
        },
        {
          key: ['s'],
          action: {
            canadianFootball: 'Handoff',
            americanFootball: 'Handoff'
          }
        },
        {
          key: ['f'],
          action: {
            canadianFootball: 'Tackle',
            americanFootball: 'Tackle'
          }
        },
        {
          key: ['n'],
          action: {
            canadianFootball: 'Penalty',
            americanFootball: 'Penalty',
            rugbyUnion: 'Start/Stop Penalty Countdown',
            soccer: 'Penalty'
          }
        }
      ]
    })
  }

  if (isTrainingMode) {
    shortcuts.push({
      screenName: 'Drills',
      shortcuts: [
        { key: ['←'], action: 'Move Drill Zone Left' },
        { key: ['→'], action: 'Move Drill Zone Right' },
        { key: ['↑'], action: 'Move Drill Zone Up' },
        { key: ['↓'], action: 'Move Drill Zone Down' },
        { key: ['Enter'], action: 'Save Drill Zone position' },
        { key: ['Esc'], action: 'Reset drill selection' }
      ]
    })
  }

  if (appTypeCheck.isAdmin && !isTrainingMode) {
    shortcuts.push({
      screenName: 'Coding',
      shortcuts: [
        { key: ['←'], action: 'Possession left' },
        { key: ['→'], action: 'Possession right' },
        {
          key: ['Space Bar'],
          action: {
            default: 'Start/Stop Play',
            rugbyUnion: 'Start/Stop Play',
            soccer: 'Start/Stop Play',
            canadianFootball: 'Snap / Kick Off & Submit Play',
            americanFootball: 'Snap / Kick Off & Submit Play'
          }
        }
      ]
    })
  }

  return shortcuts
}

export const getActionPhrase = (
  shortcut: Shortcut,
  sportType: SportTypeKeys
) => {
  if (typeof shortcut.action === 'object') {
    return shortcut.action[sportType] || shortcut.action.default
  }
  return shortcut.action
}
