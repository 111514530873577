import React from 'react'
import { Signal } from '../../../../components/Icons/Signal/Signal'
import styles from './AnchorHealthGrid.module.scss'
import { FormattedDevice } from '../../types'
import { sportableColors } from '../../../../const'
import { BatteryIcon } from '../../../../components/Icons/BatteryIcon/BatteryIcon'

export const AnchorHealthGridItem = ({
  device
}: {
  device: FormattedDevice
}) => {
  return (
    // Border here: online / offline
    <div
      className={styles.anchorItemContainer}
      style={{ borderColor: device.status.colour }}
    >
      {/* Serial */}
      <div className={styles.serial}>{device.serial}</div>
      {/* Anchor icon with signal and batt */}
      <div className={styles.imgContainer}>
        <div className={styles.anchorImgContainer}>
          <BatteryIcon device={device} isAnchor={true} />
        </div>
        <div className={styles.signalImgContainer}>
          {device.masterSignalStrength >= 0 && (
            <Signal
              scale={0.8}
              strength={
                device.status.value === 'online'
                  ? device.masterSignalStrength
                  : 0
              }
              color={sportableColors.colors.sportableBlue}
            />
          )}
        </div>
        {/* Adjust Location Overlay */}
        {(device.masterSignalStrength === 1 || device.hasFluctuated) && (
          <div className={styles.locationWarning}>
            <p>Adjust</p>
            <p>Position</p>
          </div>
        )}
      </div>
    </div>
  )
}
