import React from 'react'
import { useActiveMomentDuration } from '../../../utils/moment/hooks'
import moment from '../../../utils/moment'

export type DurationCellProps = {
  startTime: number
  endTime: number
  inputUnit: moment.unitOfTime.DurationConstructor
  displayUnit: moment.unitOfTime.DurationConstructor
  isActive?: boolean
}

export function DurationCell({
  startTime,
  endTime,
  inputUnit,
  displayUnit,
  isActive
}: DurationCellProps) {
  if (!startTime && !endTime) {
    return '-'
  }

  const duration = useActiveMomentDuration(
    startTime,
    endTime,
    inputUnit,
    isActive
  ) as any

  return <>{duration._milliseconds < 1000 ? '0:00' : duration.format()}</>
}
