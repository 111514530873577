import React from 'react'
import styles from '../HardwareItem.module.scss'
import { FormattedDevice } from '../../../../types'
import { BatteryIcon } from '../../../../../../components/Icons/BatteryIcon/BatteryIcon'
import { SportableBall } from '../../../../../../components/Icons/SportableBall/SportableBall'
import { sportableColors } from '../../../../../../const'
import { useFormattedSession } from '../../../../../sessions/hooks'
import { HardwareCommon } from '../HardwareCommon/HardwareCommon'
import { useAppDispatch } from '../../../../../../store/hooks'
import {
  addPlayerSession,
  removeBallFromSession
} from '../../../../../sessions/actions'

export interface HardwareBallProps {
  device: FormattedDevice
  isSessionAssignment: boolean
}

export const HardwareBall: React.FC<HardwareBallProps> = ({
  device,
  isSessionAssignment
}) => {
  const dispatch = useAppDispatch()

  const { playersSessions, sport } = useFormattedSession('setup')

  if (!isSessionAssignment) return <HardwareCommon device={device} />

  // Get player session
  const playerSession = playersSessions.byHardwareId.map[device.id]

  // If no player session render available ball //
  if (!playerSession) {
    return (
      <div
        id='available-ball'
        onClick={() =>
          dispatch(
            addPlayerSession({
              id: null,
              playerId: null,
              teamId: null,
              sessionId: null,
              number: null,
              player: null,
              tag: {
                id: device.id,
                serial: device.serial,
                type: 'Ball',
                productName: device.productName
              }
            })
          )
        }
        className={`${styles['hardware-item-container']}`}
      >
        <div
          style={{ backgroundColor: device.status.colour }}
          className={`${styles['hardware-item-sharedContainer']}`}
        >
          <div className={styles['hardware-item-encodedId']}>
            <div className={styles['hardware-item-batteryContainer']}>
              <BatteryIcon device={device} />
            </div>
            {device.serial}
          </div>
          <SportableBall scale={0.8} sport={sport} />
          <div className={styles['hardware-item-bottomDivBatteryAndQAInfo']}>
            <div>
              <h6 className={styles['hardware-item-batteryPercentage']}>
                {device.battery.display}
              </h6>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // Render assigned ball //
  return (
    <div
      id='linked-ball'
      className={`${styles['hardware-item-container']}`}
      onClick={() => dispatch(removeBallFromSession(device.id))}
      style={{ cursor: 'pointer' }}
    >
      <div
        style={{ backgroundColor: device.status.colour }}
        className={`${styles['hardware-item-sharedContainer']} ${styles['hardware-item-linkedBorder']}`}
      >
        <div className={styles['hardware-item-encodedId']}>
          <div className={styles['hardware-item-batteryContainer']}>
            <BatteryIcon device={device} />
          </div>
          {device.serial}
        </div>
        <SportableBall
          scale={0.8}
          color={sportableColors.colors.sportableBlue}
          sport={sport}
        />
        <div className={styles['hardware-item-bottomDivBatteryAndQAInfo']}>
          <div>
            <h6 className={styles['hardware-item-batteryPercentage']}>
              {device.battery.display}
            </h6>
          </div>
        </div>
      </div>
    </div>
  )
}
