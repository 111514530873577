import React, { useState } from 'react'
import { ActionCreator, Action } from 'redux'
import { CardList } from '../../../components/CardList/CardList'
import { EventFiltersForm } from '../../../components/EventFilters/EventFilters'
import styles from './Overview.module.scss'
import {
  Strack,
  CanvasStyle,
  CanvasViewTypes
} from '../../../components/Strack/Strack.types'
import { RawPlayerSessionData } from '../../../metrics_server/sessions/types'
import { ValidationTable } from '../../../metrics_server/events/components/ValidationTable'
import { EventsFilters } from '../../../metrics_server/events/filter'
import { StrackEvents } from '../../../components/Strack/StrackEvents/StrackEvents'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'
import { RawEventData } from '../../../metrics_server/events/types'
import { SortingState } from '@tanstack/react-table'

export interface OverviewProps {
  strackReady: boolean
  strack: Strack
  canvasStyle: CanvasStyle
  toggleCanvasView: ActionCreator<Action>
  canvasView: CanvasViewTypes
  sessionConfig: RawPlayerSessionData[]

  setOptions: ActionCreator<Action>
  active: boolean

  scrollToFlight: (
    selectedFlightId: string | number,
    tableId: string | number
  ) => void

  highlightedRow: string
  setHighlightedRow: (flightId: string) => void

  validationEventsFilters: EventsFilters
  validationTableSorting: SortingState
  setValidationTableSorting: (sorting: SortingState) => void
  timeColumn: string
  setTimeColumn: (column: string) => void
}

export function Overview({
  // Strack Canvas
  strack,
  strackReady,
  canvasStyle,
  canvasView,

  active,

  validationEventsFilters,
  validationTableSorting,
  setValidationTableSorting,
  timeColumn,
  setTimeColumn
}: OverviewProps) {
  // Table //
  const overviewTableId = 'overviewTable'
  // ==== //

  const { live, isKeyboardShortcutEnabled } = useSelectedFormattedSession()

  // Get displayed events from table to display on pitch //
  const [displayedEvents, setDisplayedEvents] = useState<RawEventData[]>([])

  // Don't render anything if the tab is not active
  if (!active) {
    return null
  }

  return (
    <React.Fragment>
      <StrackEvents
        events={displayedEvents}
        active={active}
        canvasView={canvasView}
        strack={strack}
        canvasStyle={canvasStyle}
        strackReady={strackReady}
        maxNumberOfEvents={live ? 10 : 200}
      />
      <div className={styles.filterContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <EventFiltersForm filters={validationEventsFilters} />
        </CardList>
      </div>
      <div className={styles.tableContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className='card-table-container'>
            <ValidationTable
              eventsFilters={validationEventsFilters}
              tableId={overviewTableId}
              active={active}
              updateDisplayedEvents={setDisplayedEvents}
              maxDisplayedEvents={live ? 10 : 200}
              validationTableSorting={validationTableSorting}
              setValidationTableSorting={setValidationTableSorting}
              isKeyboardShortcutEnabled={isKeyboardShortcutEnabled}
              timeColumn={timeColumn}
              setTimeColumn={setTimeColumn}
            />
          </div>
        </CardList>
      </div>
    </React.Fragment>
  )
}
